// import MediaCard from './components/MediaCard'
// import ImageCards from './components/ImageCards'
import React from "react";
import "./App.css";
import Navbar from "./components/NavBar";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Contact from "./pages/contact";
import SignUp from "./pages/signup";
import SignIn from "./pages/signin";
import Img_List from "./pages/image_list";
import AddPic from "./pages/add_picture";
import Admin from "./pages/admin";
import ImageCards from "./components/ImageCards";
import Error from "./components/Error";
import { useContext } from "react";
import AuthContext from "./Store/authContext";

function App() {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const isAdmin = authCtx.admin==="yes" ? true : false;


  // authCtx.updateValues();
  return (
    <Router>
      <Navbar />
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/Signin">
            {!isLoggedIn && <SignIn/>}
            {isLoggedIn && <Redirect to="/"/>}
        </Route>
        <Route path="/sign-up">
            {!isLoggedIn && <SignUp/>}
            {isLoggedIn && <Redirect to="/"/>}
        </Route>
        <Route path="/admin">
            {isAdmin && <Admin/>}
            {!isAdmin && <Redirect to="/"/>}
        </Route>
        <Route path="/about" component={About} />
        <Route path="/list" component={Img_List} />
        <Route path="/add_image" component={AddPic} />
        <Route path="/contact" component={Contact} />
        {isLoggedIn && <Route path="/:image" component={ImageCards} />}
        {!isLoggedIn && <Route path="/:image" component={Error} />}
      </Switch>
    </Router>
  );
  // return(
  //   <div className="max-w-sm rounded overflow-hidden shadow-lg">
  //      <MediaCard/>
  //      <ImageCards/>
  //   </div>
  // )
}

export default App;
