/* eslint-disable no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
import { onSnapshot, orderBy, startAfter } from "firebase/firestore"; //Using this access method: https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
  setDoc,
  addDoc,
  query,
  limit,
} from "firebase/firestore/lite";

import React, { useState, useEffect, useMemo } from "react";
import firebase from "firebase/compat/app";
import classes from "./imageTable.module.css";
import { Helmet } from "react-helmet";
import { BounceLoader } from "react-spinners";
import { v4 as uuid } from "uuid";
import { app, db } from "../Config/firebase";
import { where } from "firebase/firestore/lite";

const Table = ({ images, loadMoreHandler, lastDoc }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [cursor, setCursor] = useState(1);
  const [searchFilters, setSearchFilters] = useState({
    title: true,
    description: false,
    key_words: false,
    file_name: false,
    id: false,
  });

  // a function that toggles the search filters
  // if the filter is already on, it will turn it off
  // if the filter is off, it will turn it on
  const toggleSearchFilter = (filter) => {
    // if all the filters.length - 1 are off then set all to on
    if (
      Object.values(searchFilters).filter((val) => val === true).length === 1 &&
      !searchFilters[filter] === false
    )
      return;
    return setSearchFilters({
      ...searchFilters,
      [filter]: !searchFilters[filter],
    });
  };

  // a function that returns the checked state of the search filters
  const getSearchFilterState = (filter) => {
    return searchFilters[filter];
  };

  const searchFilterTypes = [
    {
      type: "title",
      label: "Title",
      checked: getSearchFilterState("title"),
      onChange: () => {
        toggleSearchFilter("title");
      },
    },
    {
      type: "description",
      label: "Description",
      checked: getSearchFilterState("description"),
      onChange: () => {
        toggleSearchFilter("description");
      },
    },
    {
      type: "key_words",
      label: "Key Words",
      checked: getSearchFilterState("key_words"),
      onChange: () => {
        toggleSearchFilter("key_words");
      },
    },
    {
      type: "file_name",
      label: "File Name",
      checked: getSearchFilterState("file_name"),
      onChange: () => {
        toggleSearchFilter("file_name");
      },
    },
    {
      type: "id",
      label: "ID",
      checked: getSearchFilterState("id"),
      onChange: () => {
        toggleSearchFilter("id");
      },
    },
  ];

  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    const abortCont = new AbortController();
    setTimeout(() => {
      if (searchQuery.length === 0) return () => abortCont.abort();

      if (Object.values(searchFilters).every((val) => val === false)) {
        return;
      }
      setIsSearching(true);
      fetch("https://fantasy-art-api-vrgjyif7jq-nw.a.run.app" + "/search", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: {
            query_string: {
              query: searchQuery,
              fields: Object.keys(searchFilters).filter(
                (key) => searchFilters[key]
              ),
            },
          },
          size: 1000,
        }),
        signal: abortCont.signal,
      })
        .then((res) => {
          if (!res.ok) {
            // error coming back from server
            throw Error("could not fetch the data for that resource");
          }
          return res.json();
        })
        .then((data) => {
          setCursor(1);
          setSearchResult(data.data);
          setIsSearching(false);
        })
        .catch((err) => {
          if (err.name === "AbortError") {
            setIsSearching(false);
          } else {
            return err.message;
          }
        });
    }, 1000);
    return () => abortCont.abort();
  }, [searchQuery, searchFilters]);

  const range = (start, end) => {
    var ans = [];
    for (let i = start; i <= end; i++) {
      ans.push(i);
    }
    return ans;
  };

  useEffect(() => {
    if (cursor * 40 > images.length) {
      loadMoreHandler();
    }
  }, [cursor]);

  const pageBtns = useMemo(() => {
    return range(cursor > 2 ? cursor - 2 : 1, cursor + 4);
  }, [cursor]);

  //

  return (
    <div className="container mx-auto px-4 sm:px-8 w-full scale-75">
      <div className="bg-white sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
        <div className="inline-block bg-white ml-6 scale-75 rounded-lg overflow-hidden">
          <div className="flex gap-6 border-b px-8 py-6">
            <input
              type="text"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              placeholder="Search..."
              className="px-6 py-2 border rounded-lg"
            />
            <div className="flex w-full mx-6 px-6 items-center lg:mx-auto rounded-lg">
              <p>Filter by:</p>
              <div className="flex gap-3 px-4">
                {searchFilterTypes.map((filter, index) => (
                  <div key={index} className="flex gap-2 items-center">
                    <input
                      type="checkbox"
                      checked={filter.checked}
                      onChange={filter.onChange}
                      className="form-checkbox h-4 w-4 text-gray-400 transition duration-150 ease-in-out"
                    />
                    <p className="text-sm text-gray-600">{filter.label}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <ListTable
            range={range}
            isSearching={isSearching}
            searchQuery={searchQuery}
            isSearched={Boolean(searchResult.length > 0)}
            data={searchResult.length > 0 ? searchResult : images}
            setCursor={setCursor}
            cursor={cursor}
            loadMoreHandler={loadMoreHandler}
            pageBtns={pageBtns}
          />
        </div>
      </div>
    </div>
  );
};

const ListTable = ({
  data,
  pageBtns,
  range,
  cursor,
  setCursor,
  searchQuery,
  loadMoreHandler,
  isSearching,
  isSearched,
}) => {
  // const [cursor, setCursor] = useState(1)

  const currentPage = useMemo(() => {
    // if(searchResult.length > 0) return searchResult.slice(cursor * 10 - 10, cursor * 10)
    return data.slice(cursor * 10 - 10, cursor * 10);
  }, [data, cursor, isSearching]);

  const pickNum = (n) => {
    if (n === "+") {
      // if(data.length < (cursor + 1) * 9) return
      return setCursor(cursor++);
      // if((n * 70) > data.length) {
      //   loadMoreHandler()
      // }
    }
    if (n === "-") {
      if (cursor === 1 || cursor < 1) return setCursor(1);
      return setCursor(cursor--);
    }
  };
  return (
    <div className="scale-90 bg-red-300">
      {isSearched && searchQuery && (
        <div className="px-6 border-b border-gray-100 py-3 bg-white">
          <h1 className="font-semibold text-lg">
            {isSearched && !isSearching
              ? `Search Result for "${searchQuery}" found ${data.length}`
              : null}
          </h1>
        </div>
      )}
      <table className="min-w-full min-h-[60%] bg-red-300 leading-normal">
        <thead>
          <tr>
            <th
              scope="col"
              className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
            >
              ID
            </th>
            <th
              scope="col"
              className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
            >
              File Name
            </th>
            <th
              scope="col"
              className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
            >
              Artist
            </th>
            {/* <th scope="col" className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal">
            Created At
          </th> */}
            <th
              scope="col"
              className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
            >
              Source
            </th>
            <th
              scope="col"
              className="px-5 py-3 w-16 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
            >
              Source URL
            </th>
            <th
              scope="col"
              className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
            >
              Tagging Status
            </th>
            <th
              scope="col"
              className="px-5 py-3 bg-white  border-b border-gray-200 text-gray-800  text-left text-sm uppercase font-normal"
            ></th>
          </tr>
        </thead>
        <tbody>
          {isSearching
            ? range(0, 10).map((i) => (
                <tr key={uuid()}>
                  {range(0, 6).map((k) => (
                    <td className="px-5 py-3 animate-pulse border-b border-gray-200 bg-white text-sm">
                      <div className="bg-gray-100 rounded-full w-full px-2 py-3"></div>
                    </td>
                  ))}
                </tr>
              ))
            : currentPage &&
              currentPage.length > 0 &&
              currentPage.map((img) => (
                <tr key={uuid()}>
                  <td className="px-3 py-3 border-b border-gray-200 bg-white text-xs">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {img.id || "Unknown"}
                    </p>
                  </td>
                  <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                    <a
                      href={img.file_name.split("/")[0]}
                      target="blank"
                      className="text-gray-900 whitespace-no-wrap"
                    >
                      <abbr title={img.file_name}>
                        {img.file_name.length > 44
                          ? img.file_name.substr(0, 44) + "..."
                          : img.file_name}
                      </abbr>
                    </a>
                  </td>
                  <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {img.artist || "Unknown"}
                    </p>
                  </td>
                  {/* <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                <p className="text-gray-900 whitespace-no-wrap">
                  {img.created_at} 
                </p>
              </td> */}
                  <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                    <p className="text-gray-900 whitespace-no-wrap">
                      {img.source}
                    </p>
                  </td>
                  <td className="px-3 py-3 border-b w-16 border-gray-200 bg-white text-xs">
                    <p className="text-gray-900 w-16 whitespace-normal">
                      <a href={img.source_url} className="text-blue-600">
                        Source Image
                      </a>
                    </p>
                  </td>
                  <td className="px-3 py-3 border-b border-gray-200 bg-white text-sm">
                    <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight">
                      {img.taggingStatus === "Untagged" ? (
                        <span className="relative bg-red-200 bg-opacity-50 px-3 py-2 rounded-full">
                          Untagged
                        </span>
                      ) : (
                        <span className="relative bg-green-200 bg-opacity-50 text-green-500 px-3 py-2 rounded-full">
                          Tagged
                        </span>
                      )}
                    </span>
                  </td>
                  <td className="pr-4 py-3 border-b border-gray-200 bg-white text-sm">
                    {
                      <button
                        onClick={() => window.open(img.download_url)}
                        className="text-indigo-600 hover:underline hover:text-indigo-900"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3"
                          />
                        </svg>
                      </button>
                    }
                  </td>
                </tr>
              ))}
        </tbody>
      </table>
      <div className="px-5 bg-white py-3 flex flex-col xs:flex-row items-center xs:justify-between">
        <div className="flex items-center">
          <button
            type="button"
            onClick={() => pickNum("-")}
            className={`w-full p-4 border text-base rounded-l-xl hover:bg-gray-100 ${
              cursor === 1
                ? "bg-gray-100 text-gray-300"
                : "text-gray-600 bg-white"
            }`}
          >
            <svg
              width="9"
              fill="currentColor"
              height="8"
              className=""
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1427 301l-531 531 531 531q19 19 19 45t-19 45l-166 166q-19 19-45 19t-45-19l-742-742q-19-19-19-45t19-45l742-742q19-19 45-19t45 19l166 166q19 19 19 45t-19 45z"></path>
            </svg>
          </button>
          {pageBtns &&
            pageBtns.map((btn) => (
              <button
                key={uuid()}
                disabled={data.length < btn * 9}
                type="button"
                onClick={() => setCursor(btn)}
                className={`w-full px-4 py-2 border-t border-r border-b text-base bg-white hover:bg-gray-100 ${
                  cursor === btn
                    ? "text-indigo-500"
                    : data.length < btn * 9
                    ? "bg-gray-100 text-gray-300"
                    : "text-gray-700"
                }`}
              >
                {btn}
              </button>
            ))}

          <button
            type="button"
            onClick={() => pickNum("+")}
            className={`w-full p-4 border-t border-b border-r text-base rounded-r-xl  hover:bg-gray-100 ${
              data.length < (cursor + 1) * 9
                ? "bg-gray-100 text-gray-300"
                : "text-gray-600 bg-white"
            }`}
          >
            <svg
              width="9"
              fill="currentColor"
              height="8"
              className=""
              viewBox="0 0 1792 1792"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M1363 877l-742 742q-19 19-45 19t-45-19l-166-166q-19-19-19-45t19-45l531-531-531-531q-19-19-19-45t19-45l166-166q19-19 45-19t45 19l742 742q19 19 19 45t-19 45z"></path>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

function ImageTable() {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [, setError] = useState(false);
  const [loadedAll, setLoadedAll] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  // const lastDoc = useMemo(() => loadedAll === true && images.length > 0 ? images[images.length - 1].doc : null, [images, loadedAll])

  const fetchWithLoading = async () => {
    setLoading(true);
    let orgList2 = [];

    const q = query(
      collection(db, "Images"),
      orderBy("id", "asc"),
      startAfter(lastDoc),
      limit(100)
    );
    const querySnapshot = await getDocs(q);
    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1].id);
    orgList2 = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        file_name: doc.data().file_name + "/" + doc.data().title,
        download_url: doc.data().download_url,
        created_at: doc.data().created_at.toString().slice(0, 21),
        artist: doc.data().artist,
        source: doc.data().source,
        source_url: doc.data().source_url,
        doc: doc, //used for pagination
        // taggingStatus: doc.data().taggingStatus,
        taggingStatus: doc.data().taggingStatus //If partial show under "in-progress", if null show as "Untagged"
          ? doc.data().taggingStatus === "Partial"
            ? "In-progress"
            : doc.data().taggingStatus
          : "Untagged",
        // doc: doc, //used for pagination
      };
    });
    setImages((prevState) => {
      return [
        ...prevState,
        ...orgList2.filter((v) => v.taggingStatus !== "Irrelevant"),
      ];
    });
    setLoadedAll(true);
    setLoading(false);
    // setError(true);
  };

  useEffect(() => {
    fetchWithLoading();
  }, []);

  const fetchWithoutLoading = async () => {
    let orgList2 = [];
    const q = query(
      collection(db, "Images"),
      orderBy("id", "asc"),
      startAfter(lastDoc),
      limit(100)
    );
    const querySnapshot = await getDocs(q);
    setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1] ? querySnapshot.docs[querySnapshot.docs.length - 1].id : null);
    orgList2 = querySnapshot.docs.map((doc) => {
      return {
        id: doc.id,
        file_name: doc.data().file_name + "/" + doc.data().title,
        download_url: doc.data().download_url,
        created_at: doc.data().created_at.toDate().toString().slice(0, 21),
        artist: doc.data().artist,
        source: doc.data().source,
        source_url: doc.data().source_url,
        doc: doc, //used for pagination
        // taggingStatus: doc.data().taggingStatus,
        taggingStatus: doc.data().taggingStatus //If partial show under "in-progress", if null show as "Untagged"
          ? doc.data().taggingStatus === "Partial"
            ? "In-progress"
            : doc.data().taggingStatus
          : "Untagged",
        // doc: doc, //used for pagination
      };
    });
    let orgList = [];
    orgList2.forEach(function (v) {
      if (v.taggingStatus !== "Irrelevant") orgList.push(v);
    });
    var arr = images.concat(orgList);
    setImages(arr);
    //       if (
    //         orgList[orgList.length - 1] !== null &&
    //         orgList[orgList.length - 1].id !== null
    //       ) {
    // setLastDoc(orgList[orgList.length - 1].doc);
    //       } else { setLoadedAll(true) };

    // setLoading(false);
    //     },
    //     () => {
    //       setError(true);
    //     }
    //   );
    //   return () => unsubscribe();
  };

  const loadMoreHandler = () => {
    return fetchWithoutLoading();
  };

  return (
    <div>
      <Helmet>
        <style>{"body { background-color: rgb(210, 208, 207); }"}</style>
      </Helmet>
      {loading ? (
        <div>
          <div className={classes.loadingDiv}>
            <BounceLoader size="100" color="orange" />
          </div>
          <div className={classes.loadingDivText}>
            <h1>Loading...</h1>
          </div>
        </div>
      ) : (
        <div className="bg-gray-100">
          <Table
            images={images}
            lastDoc={lastDoc}
            loadMoreHandler={() => loadMoreHandler()}
          />
        </div>
      )}
      {/* {loadedAll && <div>All Images Loaded!</div>} */}
    </div>
  );
}

export default ImageTable;
