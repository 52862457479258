import React, { useEffect, useRef } from "react";
import { useState } from "react";
import classes from "../components/imageTable.module.css";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { app, db } from "../Config/firebase";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from "firebase/firestore/lite";

const fileToDataUri = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      resolve(event.target.result);
    };
    reader.readAsDataURL(file);
  });

function AddPic() {
  const [currKeyword, setCurrKeyword] = useState("");
  const [dataUri, setDataUri] = useState("");
  const [imageInfo, setImageInfo] = useState(null);
  const [errorMsg, setErrorMsg] = useState("");
  const [noImageError, setNoImageError] = useState("");
  const [showModal, setShowModal] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [imageDetails, setImageDetails] = useState({
    title: "",
    key_words: [],
    source: "",
    source_url: "",
    artist: "",
    description: "",
  });

  const _InpChange = (file) => {
    setImageInfo(file);
    if (!file) {
      setDataUri("");
      return;
    }
    fileToDataUri(file).then((dataUri) => {
      setDataUri(dataUri);
    });
  };

  const setStateValue = (key, value) => {
    setImageDetails((prev) => {
      return {
        ...prev,
        [key]: value,
      };
    });
  };

  const handleReset = () => {
    setUploading(false)
    setImageInfo(null);
    setDataUri("")
    setCurrKeyword("")
    setImageDetails({
      title: "",
      key_words: [],
      source: "",
      source_url: "",
      artist: "",
      description: "",
    });
  }

  const handleUploadImage = async () => {
    const { key_words, description, source_url, source, artist, title } = imageDetails;
    if (!imageInfo) {
      setNoImageError(
        "No image is attached, please choose an image to proceed"
      );
      return setTimeout(() => {
        setNoImageError("");
      }, 4000);
    }
    if (key_words.length < 1) {
      setErrorMsg("Please type keyword and press Enter to add it to the list (e.g Nature)");
      return setTimeout(() => {
        setErrorMsg("");
      }, 4000);
    }
    if (!source || !description || !source_url || !source || !artist || !title) {
      setErrorMsg("Please fill the required fields before continuing");
      return setTimeout(() => {
        setErrorMsg("");
      }, 4000);
    }
    const storage = getStorage();
    const storageRef = ref(storage, imageInfo.name);
    setUploading(true)
    setShowModal(true)
    const uploadedImageUrl = await uploadBytes(storageRef, imageInfo).then(
      async (snapshot) => {
        const getIt = await getDownloadURL(
          ref(storage, snapshot.ref.toString())
        ).then((url) => url);
        return getIt;
      }
    );

    addDoc(
      collection(db, "Images"), {
        file_name: imageInfo.name,
        created_at: Date.now(),
        download_url: uploadedImageUrl,
        tagged: false,
        key_words,
        description,
        source_url,
        source,
        artist,
        title,
    }).then(async (document) => {
      const editRef = doc(db, "Images", document.id);
      await updateDoc(editRef, {
        id: document.id,
      }).finally(() => {
        setUploading(false)
        setImageInfo(null);
        setDataUri("")
        setImageDetails({
          title: "",
          key_words: [],
          source: "",
          source_url: "",
          artist: "",
          description: "",
        });
        setTimeout(() => {
          setShowModal(false)
        }, 2000);
      });
    });
  };

  return (
    <>
    <div className="z-10">

      <div className={`${showModal ? "" : "hidden"} fixed flex justify-center items-center z-50 bg-black bg-opacity-70 w-full h-screen`}>
        <div className="flex flex-col items-center justify-center px-16 py-16 rounded-lg bg-white">
          {
            uploading ? 
          <div className="flex items-center justify-center">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6 animate-spin">
              <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
            </svg>
            <h2>Uploading...</h2>
          </div>
          :
          <h1>Done!</h1>
          }
        </div>
      </div>

      <h1 className={classes.messageErrAdd}>Add an Image</h1>
      <p className={classes.messageErr2}>
        This page will contain the means to add a new picture using a URL or by
        uploading from the device. Under construction.
      </p>
      <div className="bg-gray-500 h-screen w-screen sm:px-8 md:px-16 sm:py-8">
        <main className="container mx-auto max-w-screen-lg text-sm h-full">
          <article
            aria-label="File Upload Modal"
            className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
          >
            <section className="h-full overflow-auto px-8 pb-6 w-full flex flex-col">
              <h1 className="pt-8 pb-3 font-semibold sm:text-lg text-gray-900">
                Add New Image
              </h1>

              <div className="py-6">
                <div className="flex flex-col justify-center items-center">
                  {imageInfo ? (
                    <img width="200" height="200" src={dataUri} alt="avatar" />
                  ) : (
                    <ul id="gallery" className="flex flex-1 flex-wrap -m-1">
                      <li
                        id="empty"
                        className="h-full w-full text-center flex flex-col justify-center items-center"
                      >
                        <img
                          className="mx-auto w-32"
                          src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                          alt="no data"
                        />
                        <span className="text-small text-gray-500">
                          No files selected
                        </span>
                      </li>
                    </ul>
                  )}

                  <input
                    type="file"
                    placeholder="Choose Image..."
                    onChange={(event) =>
                      _InpChange(event.target.files[0] || null)
                    }
                    className="border rounded-lg py-2 px-6 my-5"
                    accept=".png,.jpg,.jpeg"
                  />
                  <span className="text-red-500">{noImageError}</span>
                </div>
              </div>
              <div className="grid grid-cols-2 gap-5">
                <div className="flex flex-col gap-1">
                  <h4 className="text-md font-normal text-gray-600">
                    Image Title
                  </h4>
                  <input
                    type="text"
                    value={imageDetails.title}
                    onChange={(e) => setStateValue("title", e.target.value)}
                    placeholder="Title here..."
                    className="px-6 py-2 rounded-lg bg-gray-50 shadow-inner"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="text-md font-normal text-gray-600">Artist</h4>
                  <input
                    onChange={(e) => setStateValue("artist", e.target.value)}
                    value={imageDetails.artist}
                    type="text"
                    placeholder="Artist (e.g Picasso)"
                    className="px-6 py-2 rounded-lg bg-gray-50 shadow-inner"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="text-md font-normal text-gray-600">
                    Image Source
                  </h4>
                  <input
                    onChange={(e) => setStateValue("source", e.target.value)}
                    value={imageDetails.source}
                    type="text"
                    placeholder="Image source (e.g https://example.com)"
                    className="px-6 py-2 rounded-lg bg-gray-50 shadow-inner"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="text-md font-normal text-gray-600">
                    Image Source URL
                  </h4>
                  <input
                    onChange={(e) =>
                      setStateValue("source_url", e.target.value)
                    }
                    value={imageDetails.source_url}
                    type="text"
                    placeholder="URL (e.g https://www.example.com/pic.png)"
                    className="px-6 py-2 rounded-lg bg-gray-50 shadow-inner"
                  />
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="text-md font-normal text-gray-600">
                    Keywords
                  </h4>
                  <div className="flex flex-wrap px-2 py-2 rounded-lg bg-gray-50 shadow-inner">
                    {imageDetails.key_words && (
                      <div className="w-full flex flex-wrap items-center gap-2 pr-3">
                        {imageDetails.key_words.map((keyword) => (
                          <span
                            key={keyword}
                            onClick={() => {
                              setImageDetails(prev => {
                                return {
                                  ...prev,
                                  key_words: prev.key_words.filter(kwrd => kwrd !== keyword)
                                }
                              })
                            }}
                            className="text-black bg-gray-200 rounded-lg px-2 py-1"
                          >
                            {keyword}
                          </span>
                        ))}
                        <input
                          onChange={(e) => setCurrKeyword(e.target.value)}
                          onKeyDown={(e) => {
                            if (!e.target.value) return;
                            if (e.key === "Enter") {
                              if (
                                imageDetails.key_words.find(
                                  (keyword) => keyword === e.target.value
                                )
                              ) {
                                return setCurrKeyword("");
                              }
                              const newState = [
                                ...imageDetails.key_words,
                                e.target.value,
                              ];
                              setStateValue("key_words", newState);
                              setCurrKeyword("");
                            }
                          }}
                          value={currKeyword}
                          type="text"
                          placeholder="Keword (e.g nature)"
                          className="px-3 py-2 rounded-lg bg-gray-50 focus:outline-none"
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-1">
                  <h4 className="text-md font-normal text-gray-600">
                    Description
                  </h4>
                  <textarea
                    onChange={(e) =>
                      setStateValue("description", e.target.value)
                    }
                    value={imageDetails.description}
                    placeholder="Description..."
                    className="px-6 py-3 rounded-lg bg-gray-50 shadow-inner"
                    cols="10"
                    rows="10"
                  ></textarea>
                </div>
              </div>
            </section>

            <footer className="flex items-center justify-end px-8 border-t border-gray-200 shadow-md pb-8 pt-4">
              <div className="w-full">
                <p className="text-red-500">{errorMsg}</p>
              </div>
              <div className="flex w-4/12">
                <button
                  id="submit"
                  onClick={() => handleUploadImage()}
                  className="rounded-lg shadow px-4 py-3 bg-blue-700 hover:bg-blue-500 text-white focus:shadow-outline focus:outline-none"
                >
                  Upload now
                </button>
                <button
                  id="cancel"
                  onClick={() => handleReset()}
                  className="ml-3 rounded-sm px-4 py-3 hover:bg-gray-300 focus:shadow-outline focus:outline-none"
                >
                  Reset
                </button>
              </div>
            </footer>
          </article>
        </main>
      </div>
    </div>
    </>
  );
}

export default AddPic;
