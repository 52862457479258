import React, { useState } from "react";

const AuthContext = React.createContext({
  token: "",
  isLoggedIn: false,
  login: (token) => {},
  logout: () => {},
  updateValues: () => {},
  admin: "",
  imageListLevel: 0,
  addImageLevel: 0,
});

export const AuthContextProvider = (props) => {
  const initialToken = localStorage.getItem('token');
  const [token, setToken] = useState(initialToken);
  const initialAdmin= localStorage.getItem('admin');
  const [admin, setAdmin] = useState(initialAdmin);
  const initialImageList = localStorage.getItem('imageListLevel');
  const [imageListLevel, setImageListLevel] = useState(initialImageList);
  const initialAddImage = localStorage.getItem('addImageLevel');
  const [addImageLevel, setAddImageLevel] = useState(initialAddImage);
  const initialIsLoggedIn = localStorage.getItem('isLoggedIn');
  const [isLoggedIn, setIsLoggedIn] = useState(initialIsLoggedIn);


  const loginHandler = (token, admin, imageListLevel, addImageLevel) => {
    setIsLoggedIn(true);
    localStorage.setItem('isLoggedIn', true);
    setToken(token);
    localStorage.setItem('token', token);
    setAdmin(admin);
    localStorage.setItem('admin', admin);
    setImageListLevel(imageListLevel);
    localStorage.setItem('imageListLevel', imageListLevel);
    setAddImageLevel(addImageLevel);
    localStorage.setItem('addImageLevel', addImageLevel);
  };


  const logoutHandler = () => {
    setIsLoggedIn(false);
    localStorage.removeItem('isLoggedIn');
    setToken(null);
    localStorage.removeItem('token');
    setAdmin(null);
    localStorage.removeItem('admin');
    setImageListLevel(null);
    localStorage.removeItem('imageListLevel');
    setAddImageLevel(null);
    localStorage.removeItem('addImageLevel');
  };


  const contextValue = {
    token: token,
    login: loginHandler,
    logout: logoutHandler,
    isLoggedIn: isLoggedIn,
    admin: admin,
    imageListLevel: imageListLevel,
    addImageLevel: addImageLevel,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

// export function useAuth() {
//   return useContext(authContext);
// }

// export function AuthProvider({ children }) {
//   const [currentUser, setCurrentUser] = useState();
//   const [loading, setLoading] = useState(true);

//   function signup(email, password) {
//     return auth.createUserWithEmailAndPassword(email, password);
//   }

//   function login(email, password) {
//     return auth.signInWithEmailAndPassword(email, password);
//   }

//   function logout() {
//     return auth.signOut();
//   }

//   function resetPassword(email) {
//     return auth.sendPasswordResetEmail(email);
//   }

//   function updateEmail(email) {
//     return currentUser.updateEmail(email);
//   }

//   function updatePassword(password) {
//     return currentUser.updatePassword(password);
//   }

//   useEffect(() => {
//     const unsubscribe = auth.onAuthStateChanged((user) => {
//       setCurrentUser(user);
//       setLoading(false);
//     });

//     return unsubscribe;
//   }, []);

//   const value = {
//     currentUser,
//     login,
//     signup,
//     logout,
//     resetPassword,
//     updateEmail,
//     updatePassword,
//   };

//   return (
//     <authContext.Provider value={value}>
//       {!loading && children}
//     </authContext.Provider>
//   );
// }
