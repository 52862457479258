import { useState, useRef, useContext, useEffect } from "react";
import AuthContext from "../../Store/authContext";
import { FacebookAuthProvider, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { getFirestore, collection, getDocs, getDoc, doc, setDoc, addDoc } from 'firebase/firestore/lite';
import classes from "./AuthForm.module.css";
import { NavLink as Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import firebase from "firebase/compat/app";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { app, db } from "../../Config/firebase";
import 'firebase/firestore';

const SigninAuthForm = () => {
  const history = useHistory();
  const emailInputRef = useRef();
  const passwordInputRef = useRef();

  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const auth = getAuth(app)

  const [isLoading, setIsLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);
  const [wrongPass, setWrong] = useState(false);
  const [unexpected, setUnexpexted] = useState(false);


  const submitHandler = (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    setIsLoading(true);
    // const auth = getAuth();
    signInWithEmailAndPassword(auth, enteredEmail, enteredPassword)
      .then((userCredential) => {
        setIsLoading(false);
        const user = userCredential.user;
        // authCtx.login(user.getIdToken);
        // firebase.firestore().collection("administration").doc(user.email).get().then((snapshot) => {
        //     authCtx.login(
        //       user.email,
        //       snapshot.data().admin,
        //       snapshot.data().imageList,
        //       snapshot.data().addImage
        //     );
        //   })
        //   .catch((e) => console.log(e));
        const docRef = doc(db, "administration", user.email);
          getDoc(docRef).then(doc => {
                authCtx.login(
                  user.email,
                  doc.data().admin,
                  doc.data().imageList,
                  doc.data().addImage
                );
            })
            .catch((e) => console.log(e));
      })
      .then((data) => {
        setIsLoading(false);
        history.replace("/list");
      })
      .catch((error) => {
        setIsLoading(false);
        const errorCode = error.code;
        if (errorCode === "auth/user-not-found") {
          setNotFound(true);
          setWrong(false);
          setUnexpexted(false);
        } else if (errorCode === "auth/wrong-password") {
          setWrong(true);
          setNotFound(false);
          setUnexpexted(false);
        } else {
          setWrong(false);
          setNotFound(false);
          setUnexpexted(true);
        }
        console.log("Error Code: " + errorCode);
        const errorMessage = error.message;
        console.log("Error Message: " + errorMessage);
        // alert("Error Code: " + errorCode + "Error Message: " + errorMessage);
      });
  };

  //////////////////Google and Facebook Sign in

  // Configure FirebaseUI.
  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      GoogleAuthProvider.PROVIDER_ID,
      FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: (authResult) => {
        const inf = authResult.additionalUserInfo;
        //If the user is new, add them to the administration collection.
        const email = auth.currentUser.email.toLowerCase();
        if (inf.isNewUser) {
            const docRef = doc(db, "administration", email)
             setDoc(docRef, {
                      addImage: 1,
                      admin: "no",
                      imageList: 1,
                    }).then((data) => {
                        authCtx.login(email, "no", 1, 1);
                    })
                    .catch((e) => console.log(e))
            }
        // if (!inf.isNewUser) {
            const docRef = doc(db, "administration", email);
            (getDoc(docRef).then(doc => {
                // console.log(doc.data())
                authCtx.login(
                    email,
                    doc.data().admin,
                    doc.data().imageList,
                    doc.data().addImage
                    );
            })
            .catch((e) => console.log(e)))
        // }
        return false;
    },

},
  };

  //////////////////END: Google and Facebook Sign in

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(auth, (user) => {
        if (user) {
          history.push("/")
        }
      });
      unregisterAuthObserver()
  }, [authCtx.isLoggedIn])

  if (!isLoggedIn) {
    return (
      <section className={classes.auth}>
        <Helmet>
          <style>{"body { background-color: rgb(210, 208, 207); }"}</style>
        </Helmet>
        <h1>Sign in</h1>
        <form onSubmit={submitHandler}>
          <div className={classes.control}>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              required
              ref={emailInputRef}
              placeholder="Enter Email"
            />
          </div>
          <div className={classes.control}>
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              required
              ref={passwordInputRef}
              placeholder="Enter Password"
            />
          </div>
          <div className={classes.actions}>
            {!isLoading && (
              <button className={classes.authButton}>Sign in</button>
            )}
            {!isLoading && notFound && (
              <div className={classes.warning}>
                <br />
                Email doesn't exist.
              </div>
            )}
            {!isLoading && wrongPass && (
              <div className={classes.warning}>
                <br />
                Password or Email is invalid.
              </div>
            )}
            {!isLoading && unexpected && (
              <div className={classes.warning}>
                <br />
                Something went wrong! Please try again
              </div>
            )}
            {isLoading && <p>Logging you in..</p>}
            <br />
            <div>Or</div>
            <StyledFirebaseAuth
              uiCallback={(ui) => ui.disableAutoSignIn()}
              uiConfig={uiConfig}
              firebaseAuth={auth}
            />

            {/* <button
            type="button"
            className={classes.toggle}
            onClick={signoutHandler}
          >
            Sign out
          </button> */}
            <Link to="/sign-up" className={classes.toggle}>
              Don't have an account? Click here to Register
            </Link>
          </div>
        </form>
        {/* <StyledFirebaseAuth
        uiConfig={this.uiConfig}
        /> */}
      </section>
    );
  }
  // if (isLoggedIn) {
  //   //Logged in
  //   return (
  //     <button
  //       type="button"
  //       className={classes.toggle}
  //       onClick={switchAuthModeHandler}
  //     >
  //       Sign in status checker
  //     </button>
  //   );
  // }
};

export default SigninAuthForm;
