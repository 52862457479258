import React from 'react'
import AdminUsersList from "../components/adminUsersList";

function Admin() {


    return (
        <div>
            <AdminUsersList />
        </div>
    )
}

export default Admin;