import React, { useEffect } from "react";
import ImageTable from "../components/ImageTable";
import { useContext } from "react";
import AuthContext from "../Store/authContext";
import { NavLink as Link } from "react-router-dom";
import firebase from "firebase/compat/app";
import classes from "../components/imageTable.module.css";
import { BounceLoader } from "react-spinners";
import { getAuth } from "firebase/auth";
import { app } from '../Config/firebase'

const Img_List = () => {
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const isList = authCtx.imageListLevel > 0 ? true : false;
  const auth = getAuth(app)
  var user = auth.currentUser;

  //Visitor is not authenticated
  if (!isLoggedIn && !user) {
    return (
      <div className = {classes.messageErr}>
        <h1>Please Sign-in to view this page</h1>
        <Link
          to="/signin"
          className = {classes.messageErrLink}
        >
          To sign in press here
        </Link>
      </div>
    );
  } else if (!isLoggedIn && user) {
    //User is redirected after successfully signing in (firestore auth but local cache is processing).
    return (
      <div>
        <div className={classes.loadingDiv}>
          <BounceLoader size="100" color="orange" />
        </div>
        <div className={classes.loadingDivText}>
          <h1>Signing you in...</h1>
        </div>
      </div>
    );
  } else if (isLoggedIn) {
    // User is logged in.
    return (
      //if isList is true the user has access, otherwise the user doesn't have access to this page.
      <div className="w-full rounded overflow-hidden shadow-lg">
        {isList && <ImageTable />}
        {!isList && (
          <div className = {classes.messageErr}>
            <h2>
              You do not have access to this page, please contact the site
              administrator with an email to gain access.
            </h2>
          </div>
        )}
      </div>
    );
  }
};

export default Img_List;
