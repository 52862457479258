/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { useState, useEffect, useContext } from "react";
import classes from "./ImageCard.module.css";
import { app, db } from "../Config/firebase";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "./react-tabs.css";
// import firebase from "firebase/compat/app";
import {getAuth} from "firebase/auth"
import Select from "react-select";
import AuthContext from "../Store/authContext";
import Creatable from "react-select/creatable";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import { collection, getDoc, updateDoc, doc, getDocs, Firestore, FieldValue, increment } from "firebase/firestore/lite";

const ImageCard = ({ image }) => {
  //States and button handlers to handle image toggle
  const [isImageActive, setImageActive] = useState(true);
  const [ImageButtonText, setImageButtonText] = useState(">");
  const [updatingStatus, setUpdatingStatus] = useState(false);
  const [status, setStatus] = useState(image.taggingStatus != null ? image.taggingStatus : "Untagged");
  const [irr, setIrr] = useState(image.taggingStatus === "Irrelevant" ? true : false);

  const [opts, setOpts] = useState([])
  const imageRef = doc(db, "Images", image.id);

  useEffect(() => {
    async function getOpts () {
      setUpdatingStatus(false)
      fetch("https://fantasy-art-api-vrgjyif7jq-nw.a.run.app" + "/allopts")
      .then(res => res.json())
      .then(data => {
        setOpts(data)
      })
    }
    getOpts()
  }, [])

  const {
    materialOptions,
    pointInTimeOptions,
    cosmologyOptions,
    kindOptions,
    envOptions,
    narOptions,
    styleOptions,
    motifsOptions,
    interactionOptions,
    assosOptions,
    contextOptions,
    figureKindOptions,
    genderOptions,
    raceOptions,
    animalsOptions,
    vegetationOptions,
    backgroundOptions,
    attributesOptions,
    poseOptions,
    orientationOptions,
    roleOptions,
    landscapeOptions,
    ffOptions,
    faOptions
  } = opts

  // New - Update status to 'Partial' when updates happen if it wasn't manually marked as Tagged.
  function updateTaggingStatus() {
    if (image.taggingStatus === "Tagged") setStatus("Tagged");
    if (image.taggingStatus === "Partial") setStatus("Partial");
    if (image.taggingStatus === "Irrelevant") {
      setStatus("Irrelevant");
      setIrr(true);
    }
    if (image.taggingStatus === "Untagged") setStatus("Untagged");
    if (
      (status === "Untagged" || image.taggingStatus === null) &&
      irr !== true
    ) {
      const imageRef = doc(db, "Images", image.id);
      updateDoc(imageRef, { taggingStatus: "Partial" }).then(msg => {
        if(status === "Untagged") {
          const imageRef2 = doc(db, "counter", "counter");
          updateDoc(imageRef2, { Untagged: FieldValue.increment(-1) }).then(msg2 => {
            const imageRef3 = doc(db, "counter", "counter");
            updateDoc(imageRef3, { Partial: FieldValue.increment(1)}).then(msg3 => {
              setStatus("Partial");
              setUpdatingStatus(false)
            })
          })
        }else if (status !== "Partial") {
          const imageRef4 = doc(db, "counter", "counter");
            updateDoc(imageRef4, { Partial: FieldValue.increment(1) }).then(msg => {
              setStatus("Partial");
              setUpdatingStatus(false);
            })
          }
      })
    }
  }

  const cosmDefault = [];
  if (image.cosmology != null) {
    for (const i of image.cosmology) {
      // console.log(i);
      cosmDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const kindDefault = [];

  if (image.kind != null) {
    for (const i of image.kind) {
      // console.log(i);
      kindDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const envDefault = [];
  if (image.environment != null) {
    for (const i of image.environment) {
      // console.log(i);
      envDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const styleDefault = [];
  if (image.style != null) {
    for (const i of image.style) {
      // console.log(i);
      styleDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const motifsDefault = [];
  if (image.motifs != null) {
    for (const i of image.motifs) {
      // console.log(i);
      motifsDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const assosDefault = [];
  if (image.assosiation != null) {
    for (const i of image.assosiation) {
      // console.log(i);
      assosDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const contextDefault = [];
  if (image.context != null) {
    for (const i of image.context) {
      // console.log(i);
      contextDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const attributesDefault = [];
  if (image.attributes != null) {
    for (const i of image.attributes) {
      // console.log(i);
      attributesDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const poseDefault = [];
  if (image.pose != null) {
    for (const i of image.pose) {
      // console.log(i);
      poseDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const roleDefault = [];
  if (image.role != null) {
    for (const i of image.role) {
      // console.log(i);
      roleDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const ffDefault = [];
  if (image.figure_figure != null) {
    for (const i of image.figure_figure) {
      // console.log(i);
      ffDefault.push({
        label: i,
        value: i,
      });
    }
  }

  const faDefault = [];
  if (image.figure_attribute != null) {
    for (const i of image.figure_attribute) {
      // console.log(i);
      faDefault.push({
        label: i,
        value: i,
      });
    }
  }

  function toggleImage() {
    setImageActive(!isImageActive);
  }

  function toggleImageButton() {
    if (ImageButtonText === "<") setImageButtonText(">");
    else setImageButtonText("<");
  }
  //End of Image handlers

  //Button handler to toggle Form
  const [isFormActive, setFormActive] = useState(true);
  const [formButtonText, setFromButtonText] = useState("<");

  function toggleForm() {
    setFormActive(!isFormActive);
  }

  function toggleFormButton() {
    if (formButtonText === ">") setFromButtonText("<");
    else setFromButtonText(">");
  }
  //End of Button handler to toggle Form

  function onChangeMaterial(event) {
    // console.log(event.value);
    updateDoc(imageRef, { material: event.value })
    // db.collection("Images").doc(image.id).update({ material: event.value });
    updateTaggingStatus();
  }

  function onChangePointInTime(event) {
    // console.log(event.value);
    updateDoc(imageRef, { pointInTime: event.value })
    // db.collection("Images").doc(image.id).update({ pointInTime: event.value });
    updateTaggingStatus();
  }

  function onChangeCosmo(event) {
    // let value = Array.from(e.target.selectedOptions, option => option.value);
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { cosmology: values })
    // db.collection("Images").doc(image.id).update({ cosmology: values });
    updateTaggingStatus();
  }

  function onChangeKind(event) {
    // let value = Array.from(e.target.selectedOptions, option => option.value);
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { kind: values })
    // db.collection("Images").doc(image.id).update({ kind: values });
    updateTaggingStatus();
  }

  function onChangeEnv(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { environment: values })
    // db.collection("Images").doc(image.id).update({ environment: values });
    updateTaggingStatus();
  }

  function onChangeNar(event) {
    // console.log(event.value);
    updateDoc(imageRef, { narativity: event.value })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ narativity: event.value });
  }

  function onChangeStyle(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { style: values })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ style: values });
  }

  function onChangeMotifs(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { motifs: values })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ motifs: values });
  }

  function onChangeInteraction(event) {
    console.log(event.value);
    updateDoc(imageRef, { interactionWithViewer: event.value })
    updateTaggingStatus();
    // db.collection("Images")
    //   .doc(image.id)
    //   .update({ interactionWithViewer: event.value });
  }

  function onChangeAssos(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { assosiation: values })
    // db.collection("Images").doc(image.id).update({ assosiation: values });
    updateTaggingStatus();
  }

  function onChangeContext(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { context: values })
    // db.collection("Images").doc(image.id).update({ context: values });
    updateTaggingStatus();
  }

  function onChangeFigureKind(event) {
    // console.log(event.value);
    updateDoc(imageRef, { figureKind: event.value })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ figureKind: event.value });
  }

  function onChangeGender(event) {
    // console.log(event.value);
    updateDoc(imageRef, { gender: event.value })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ gender: event.value });
  }

  function onChangeRace(event) {
    // console.log(event.value);
    updateDoc(imageRef, { race: event.value })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ race: event.value });
  }

  function onChangeAnimals(event) {
    // console.log(event.value);
    updateDoc(imageRef, { animals: event.value })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ animals: event.value });
  }

  function onChangeVegetation(event) {
    // console.log(event.value);
    updateDoc(imageRef, { vegetation: event.value })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ vegetation: event.value });
  }

  async function onChangeBackground(event) {
    // console.log(event.value);
    const docRef = doc(db, "Images", image.id);
    updateDoc(docRef, { background: event.value }).then(res => {
      updateTaggingStatus();
    })
    // db.collection("Images").doc(image.id).update({ background: event.value });
  }

  function onChangeAttributes(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { attributes: values })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ attributes: values });
  }

  function onChangePose(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { pose: values })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ pose: values });
  }

  function onChangeOrientation(event) {
    // console.log(event.value);
    updateDoc(imageRef, { orientation: event.value })
    updateTaggingStatus();
    // db.collection("Images").doc(image.id).update({ orientation: event.value });
  }

  function onChangeRole(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { role: values })
    // db.collection("Images").doc(image.id).update({ role: values });
    updateTaggingStatus();
  }

  function onChangeFlandscape(event) {
    // console.log(event.value);
    // db.collection("Images")
    //   .doc(image.id)
    //   .update({ figure_landscape: event.value });
    updateDoc(imageRef, { figure_landscape: event.value })
    updateTaggingStatus();
  }

  function onChangeAlandscape(event) {
    // // console.log(event.value);
    // db.collection("Images")
    //   .doc(image.id)
    //   .update({ attribute_landscape: event.value });
    updateDoc(imageRef, { attribute_landscape: event.value })
    updateTaggingStatus();
  }

  function onChangeFf(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { figure_figure: values })
    // db.collection("Images").doc(image.id).update({ figure_figure: values });
    updateTaggingStatus();
  }

  function onChangeFa(event) {
    const values = [];
    for (const element of event) {
      values.push(element.value);
    }
    updateDoc(imageRef, { figure_attribute: values })
    // db.collection("Images").doc(image.id).update({ figure_attribute: values });
    updateTaggingStatus();
  }



  const markAsTagged = () => {
    setUpdatingStatus(true);
    updateDoc(doc(db, "Images", image.id), { taggingStatus: "Tagged" }).then(msg => {
      if (status === "Partial") {
        // updateDoc(doc(db, "counter", "counter"), { Partial: increment(-1) }).then(msg2 => {
        //   updateDoc(doc(db, "counter", "counter"), { Tagged: increment(1) }).then(msg3 => {
        //     setStatus("Tagged");
        //     setUpdatingStatus(false);
        //   })
        // })
      } else if (status === "Untagged" || status === null) {
        // updateDoc(doc(db, "counter", "counter"), { Untagged: increment(-1) }).then(msg => {
        //   updateDoc(doc(db, "counter", "counter"), { Tagged: increment(1) }).then(msg2 => {
        //     setStatus("Tagged");
        //     setUpdatingStatus(false);
        //   })
        // })
      }
    })
  };

  const removeMarkAsTagged = () => {
    setUpdatingStatus(true);
    updateDoc(doc(db, "Images", image.id), { taggingStatus: "Partial" }).then(msg => {
      // updateDoc(doc(db, "counter", "counter"), { Tagged: increment(-1) }).then(msg2 => {
      //   updateDoc(doc(db, "counter", "counter"), { Partial: increment(1) }).then(msg3 => {
      //     setStatus("Partial");
      //     setUpdatingStatus(false);
      //   })
      // })
    })
  };

  const markAsIrr = () => {
    setUpdatingStatus(true);
    updateDoc(doc(db, "Images", image.id), { taggingStatus: "Partial" }).then(msg => {
      if (status === "Partial") {
        // updateDoc(doc(db, "counter", "counter"), { Partial: increment(-1) }).then(msg2 => {
        //   updateDoc(doc(db, "counter", "counter"), { Irrelevant: increment(1) }).then(msg3 => {
        //      setStatus("Irrelevant");
        //      setIrr(true);
        //      setUpdatingStatus(false);
        //   })
        // })
      } else if (status === "Untagged" || status === null) {
        // updateDoc(doc(db, "counter", "counter"), { Untagged: increment(-1) }).then(msg2 => {
        //   updateDoc(doc(db, "counter", "counter"), { Irrelevant: increment(1) }).then(msg3 => {
        //     setStatus("Irrelevant");
        //     setIrr(true);
        //     setUpdatingStatus(false);
        //   })
        // })
      } else if (status === "Tagged") {
        // updateDoc(doc(db, "counter", "counter"), { Tagged: increment(-1) }).then(msg2 => {
        //   updateDoc(doc(db, "counter", "counter"), { Irrelevant: increment(1) }).then(msg3 => {
        //      setStatus("Irrelevant");
        //      setIrr(true);
        //      setUpdatingStatus(false);
        //   })
        // })
      }
    })
  };

  const removeMarkAsIrr = () => {
    setUpdatingStatus(true);
    setUpdatingStatus(true);
    updateDoc(doc(db, "Images", image.id), { taggingStatus: "Partial" }).then(msg => {
       // updateDoc(doc(db, "counter", "counter"), { Irrelevant: increment(-1) }).then(msg2 => {
        //   updateDoc(doc(db, "counter", "counter"), { Partial: increment(1) }).then(msg3 => {
          setStatus("Partial");
          setIrr(false);
          setUpdatingStatus(false);
        //   })
        // })
    })
  };

  //Auth and Role info.
  const authCtx = useContext(AuthContext);
  const isLoggedIn = authCtx.isLoggedIn;
  const auth = getAuth(app)
  const isList = authCtx.imageListLevel > 1 ? true : false;
  var user = auth.currentUser;

  if (!isLoggedIn && !user) {
    //Visitor is not authenticated
    return (
      <div className={classes.messageErr}>
        <h1>Please Sign-in to view this page</h1>
        <Link to="/signin" className={classes.messageErrLink}>
          To sign in press here
        </Link>
      </div>
    );
  }
  // User is Auth, but they dont have access to this page
  else if (!isList) {
    return (
      <div className={classes.messageErr}>
        <h2>
          You do not have access to this page, please contact the site
          administrator with an email to gain access.
        </h2>
      </div>
    );
  }
  // User is Auth, and they have access.
  else if (isLoggedIn && isList && opts) {
    return (
      // opts && Object.keys(opts).length > 0 &&
      <div className={classes.comp}>
        <Helmet>
          <style>{"body { background-color: rgb(210, 208, 207); }"}</style>
        </Helmet>
        <div className={classes.parent}>
          <br />
          Image: <b>{image.title}</b>
          <br />
          By: <b>{image.Artist}</b>
          <br />
          Description: <b>{image.description}</b>
          <br />
          Status: <b>{status}</b>
          <br />
          {updatingStatus === true && (
            <LoadingOverlay
              active="true"
              spinner={
                <div className={classes.loaderWrapper}>
                  <div className={classes.loadingDiv}>
                    <BounceLoader size="100" color="orange" />
                  </div>
                  <div className={classes.loadingDivText}>
                    <h1>Updating status...Please wait...</h1>
                  </div>
                </div>
              }
            ></LoadingOverlay>
          )}
          {irr === false && updatingStatus === false && (
            <button
              className={classes.delete}
              type="button"
              onClick={markAsIrr}
            >
              Delete from database/Mark as irrelevant
            </button>
          )}
          {irr === true && updatingStatus === false && (
            <button
              className={classes.delete}
              type="button"
              onClick={removeMarkAsIrr}
            >
              Unmark as irrelevant
            </button>
          )}
          <br />
          <div className={classes.imageChild}>
            {/*Only display if Image button is "active" */}
            {isImageActive && (
              <img src={image.download_url} alt="" className={classes.image} />
            )}
            <button
              className={classes.btn}
              onClick={function () {
                toggleImage();
                toggleImageButton();
              }}
            >
              {ImageButtonText}
            </button>
          </div>
          {opts ? <div className={classes.buttonChild}>
            <button
              className={classes.btn2}
              onClick={function () {
                toggleForm();
                toggleFormButton();
              }}
            >
              {formButtonText}
            </button>
            {isFormActive && (
              <div className={classes.formChild}>
                <Tabs>
                  <TabList>
                    <Tab>MetaData</Tab>
                    <Tab>Image Definition</Tab>
                    <Tab>Figure Definition</Tab>
                    <Tab>Relationships</Tab>
                  </TabList>

                  <TabPanel>
                    <form className={classes.form}>
                      <label htmlFor="title">Material</label>
                      <Select
                        options={materialOptions}
                        defaultValue={{
                          label: image.material,
                          value: image.material,
                        }}
                        onChange={onChangeMaterial}
                      />
                    </form>
                  </TabPanel>

                  <TabPanel>
                    <label htmlFor="title">
                      <b>Point in Time:</b> [Single + Custom]
                    </label>
                    <Creatable
                      options={pointInTimeOptions}
                      onChange={onChangePointInTime}
                      // onInputChange={onChangePointInTime}
                      defaultValue={{
                        label: image.pointInTime,
                        value: image.pointInTime,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Cosmology:</b> [Multi + Custom]
                    </label>
                    <Creatable
                      isMulti
                      options={cosmologyOptions}
                      onChange={onChangeCosmo}
                      defaultValue={cosmDefault}
                    />

                    <label htmlFor="title">
                      <b>Kind:</b> [Multi]
                    </label>
                    <Select
                      isMulti
                      options={kindOptions}
                      onChange={onChangeKind}
                      defaultValue={kindDefault}
                    />

                    <label htmlFor="title">
                      <b>Environment:</b> [Multi]
                    </label>
                    <Select
                      isMulti
                      options={envOptions}
                      onChange={onChangeEnv}
                      defaultValue={envDefault}
                    />

                    <label htmlFor="title">
                      <b>Narartivity:</b> [Single]
                    </label>
                    <Select
                      options={narOptions}
                      onChange={onChangeNar}
                      defaultValue={{
                        label: image.narativity,
                        value: image.narativity,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Style and Composition:</b> [Multi]
                    </label>
                    <Select
                      isMulti
                      options={styleOptions}
                      onChange={onChangeStyle}
                      defaultValue={styleDefault}
                    />

                    <label htmlFor="title">
                      <b>Motifs:</b> [Multi + Custom]
                    </label>
                    <Creatable
                      isMulti
                      options={motifsOptions}
                      onChange={onChangeMotifs}
                      defaultValue={motifsDefault}
                    />

                    <label htmlFor="title">
                      <b>Interation with the viewer:</b> [Single]
                    </label>
                    <Select
                      options={interactionOptions}
                      onChange={onChangeInteraction}
                      defaultValue={{
                        label: image.interactionWithViewer,
                        value: image.interactionWithViewer,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Assosiation to previes arts:</b> [Multi]
                    </label>
                    <Select
                      isMulti
                      options={assosOptions}
                      onChange={onChangeAssos}
                      defaultValue={assosDefault}
                    />

                    <label htmlFor="title">
                      <b>Context: </b> [Multi]
                    </label>
                    <Select
                      isMulti
                      options={contextOptions}
                      onChange={onChangeContext}
                      defaultValue={contextDefault}
                    />
                  </TabPanel>
                  <TabPanel>
                    <label htmlFor="title">
                      <b>Kind: </b> [Single]
                    </label>
                    <Select
                      options={figureKindOptions}
                      onChange={onChangeFigureKind}
                      defaultValue={{
                        label: image.figureKind,
                        value: image.figureKind,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Gender: </b> [Single]
                    </label>
                    <Select
                      options={genderOptions}
                      onChange={onChangeGender}
                      defaultValue={{
                        label: image.gender,
                        value: image.gender,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Race: </b> [Single]
                    </label>
                    <Select
                      options={raceOptions}
                      onChange={onChangeRace}
                      defaultValue={{
                        label: image.race,
                        value: image.race,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Animals: </b> [Single]
                    </label>
                    <Select
                      options={animalsOptions}
                      onChange={onChangeAnimals}
                      defaultValue={{
                        label: image.animals,
                        value: image.animals,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Vegetation: </b> [Single]
                    </label>
                    <Select
                      options={vegetationOptions}
                      onChange={onChangeVegetation}
                      defaultValue={{
                        label: image.vegetation,
                        value: image.vegetation,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Background: </b> [Single]
                    </label>
                    <Select
                      options={backgroundOptions}
                      onChange={onChangeBackground}
                      defaultValue={{
                        label: image.background,
                        value: image.background,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Attributes: </b> [Multi]
                    </label>
                    <Select
                      isMulti
                      options={attributesOptions}
                      onChange={onChangeAttributes}
                      defaultValue={attributesDefault}
                    />

                    <label htmlFor="title">
                      <b>Pose: </b> [Multi]
                    </label>
                    <Select
                      isMulti
                      options={poseOptions}
                      onChange={onChangePose}
                      defaultValue={poseDefault}
                    />

                    <label htmlFor="title">
                      <b>Orientation: </b> [Single]
                    </label>
                    <Select
                      options={orientationOptions}
                      onChange={onChangeOrientation}
                      defaultValue={{
                        label: image.orientation,
                        value: image.orientation,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Role:</b> [Multi + Custom]
                    </label>
                    <Creatable
                      isMulti
                      options={roleOptions}
                      onChange={onChangeRole}
                      defaultValue={roleDefault}
                    />
                  </TabPanel>

                  <TabPanel>
                    <label htmlFor="title">
                      <b>Figure - Landscape: </b> [Single]
                    </label>
                    <Select
                      options={landscapeOptions}
                      onChange={onChangeFlandscape}
                      defaultValue={{
                        label: image.figure_landscape,
                        value: image.figure_landscape,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Attribute - Landscape: </b> [Single]
                    </label>
                    <Select
                      options={landscapeOptions}
                      onChange={onChangeAlandscape}
                      defaultValue={{
                        label: image.attribute_landscape,
                        value: image.attribute_landscape,
                      }}
                    />

                    <label htmlFor="title">
                      <b>Figure-Figure::</b> [Multi + Custom]
                    </label>
                    <Creatable
                      isMulti
                      options={ffOptions}
                      onChange={onChangeFf}
                      defaultValue={ffDefault}
                    />

                    <label htmlFor="title">
                      <b>Figure-Attribute:</b> [Multi + Custom]
                    </label>
                    <Creatable
                      isMulti
                      options={faOptions}
                      onChange={onChangeFa}
                      defaultValue={faDefault}
                    />
                  </TabPanel>
                </Tabs>
              </div>
            )}
            {status !== "Tagged" && irr !== true && (
              <button
                className={classes.mark}
                type="button"
                onClick={markAsTagged}
              >
                Mark as tagged
              </button>
            )}
            {status === "Tagged" && (
              <button
                className={classes.mark}
                type="button"
                onClick={removeMarkAsTagged}
              >
                Unmark as Tagged
              </button>
            )}
          </div>
          :
          // show loading
            <div>
              <div className={classes.loadingDiv}>
                <BounceLoader size="100" color="orange" />
              </div>
              <div className={classes.loadingDivText}>
                <h1>Loading...</h1>
              </div>
            </div>

          }
          {/* 2nd Child */}
        </div>
        {/* Parent */}
      </div> //End
    );
  }
};

export default ImageCard;
