// import {initializeApp} from "firebase/compat/app";
// import { getFirestore } from 'firebase/firestore';
// import { getStorage } from "firebase/storage";
// import firebase from "firebase/compat/app";
// import 'firebase/firestore';
// import {initializeApp} from 'firebase/app'
// import { FieldValue } from 'firebase/firestore';

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore/lite';
import { getStorage } from 'firebase/storage'

var firebaseConfig = {
  apiKey: "AIzaSyAvvZsAOdZO5BFaClAoevaPFaXy-x0Y5rY",
  authDomain: "fantasy-art.firebaseapp.com",
  projectId: "fantasy-art",
  storageBucket: "fantasy-art.appspot.com",
  messagingSenderId: "986966361867",
  appId: "1:986966361867:web:4edb1605495e5d1e3ac40e",
  measurementId: "G-FENMYKC8LG"
  };

// const auth = getAuth()
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storageRef = getStorage()

// export { storageRef, projectFirestore, timestamp, db, app };
export { db, app, storageRef };

  

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyAvvZsAOdZO5BFaClAoevaPFaXy-x0Y5rY",
//   authDomain: "fantasy-art.firebaseapp.com",
//   projectId: "fantasy-art",
//   storageBucket: "fantasy-art.appspot.com",
//   messagingSenderId: "986966361867",
//   appId: "1:986966361867:web:4edb1605495e5d1e3ac40e",
//   measurementId: "G-FENMYKC8LG"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);