import { useState, useRef, useContext } from "react";
import { NavLink as Link } from "react-router-dom";
// import firebase from "firebase/compat/app";
import AuthContext from "../../Store/authContext";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

import classes from "./AuthForm.module.css";
import { collection, doc, setDoc } from "firebase/firestore/lite";
import { db } from "../../Config/firebase";
import { addDoc } from "firebase/firestore";

const SignupAuthForm = () => {
  const emailInputRef = useRef();
  const passwordInputRef = useRef();
  const history = useHistory();

  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [, setSuccess] = useState(false); //If registered successfully - We need to login afterwards.
  const [emailExists, setEmailExists] = useState(false);
  const [weakPass, setWeakPass] = useState(false);
  const [errorSignup, setErrorSignup] = useState(false);

  const submitHandler = (event) => {
    event.preventDefault();
    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;

    //Validation

    setIsLoading(true);
    fetch(
      "https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyAvvZsAOdZO5BFaClAoevaPFaXy-x0Y5rY",
      {
        method: "POST",
        body: JSON.stringify({
          email: enteredEmail,
          password: enteredPassword,
          returnSecureToken: true,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((res) => {
      setIsLoading(false);
      if (res.ok) {
        console.log(
          "Registered " + enteredEmail + " with passowrd " + enteredPassword
        );
        alert(
          "Registered " + enteredEmail + " with passowrd " + enteredPassword
        );
        //To sign-in afterwards.
        const lowerCaseEmail = enteredEmail.toLowerCase();
        setSuccess(true);

        const auth = getAuth();
        console.log("In sign in: " + lowerCaseEmail);
        signInWithEmailAndPassword(auth, lowerCaseEmail, enteredPassword)
          .then((userCredential) => {
            setIsLoading(false);
            // authCtx.login(user.getIdToken);
            addDoc(collection(db, "administration", lowerCaseEmail), {
              addImage: 1,
              admin: "no",
              imageList: 1,
            })
              .then((data) => {
                authCtx.login(lowerCaseEmail, "no", 1, 1);
              })
              .catch((e) => console.log(e));
          })
          .then((data) => {
            setIsLoading(false);
            history.replace("/list");
          })
          .catch((error) => {
            setIsLoading(false);
            const errorCode = error.code;
            console.log("Error Code: " + errorCode);
            const errorMessage = error.message;
            console.log("Error Message: " + errorMessage);
            alert(
              "Error Code: " + errorCode + "Error Message: " + errorMessage
            );
          });
      } else {
        return res.json().then((data) => {
          let errorMsg = "Failed to Register this account!";
          setSuccess(false);
          if (data && data.error && data.error.message) {
            errorMsg = data.error.message;
          }
          if (errorMsg === "EMAIL_EXISTS") {
            setEmailExists(true);
            setWeakPass(false);
            setErrorSignup(false);
            // alert("Error: A user already registered with this email!");
          } else if (
            errorMsg ===
            "WEAK_PASSWORD : Password should be at least 6 characters"
          ) {
            setWeakPass(true);
            setEmailExists(false);
            setErrorSignup(false);
          } else {
            setErrorSignup(true);
            setWeakPass(false);
            setEmailExists(false);
          }
        });
      }
    });
  };

  // https://identitytoolkit.googleapis.com/v1/accounts:signInWithCustomToken?key=[AIzaSyAvvZsAOdZO5BFaClAoevaPFaXy-x0Y5rY]

  return (
    <section className={classes.auth}>
      <Helmet>
        <style>{"body { background-color: rgb(210, 208, 207); }"}</style>
      </Helmet>
      <h1>Sign Up</h1>
      <form onSubmit={submitHandler}>
        <div className={classes.control}>
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            required
            ref={emailInputRef}
            placeholder="Enter Email"
          />
        </div>
        <div className={classes.control}>
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            required
            ref={passwordInputRef}
            placeholder="Enter Password"
          />
        </div>
        {!isLoading && emailExists && (
          <div className={classes.warning}>
            <br />
            An account with this Email already exists!
          </div>
        )}
        {!isLoading && weakPass && (
          <div className={classes.warning}>
            <br />
            Password should be at least 6 characters
          </div>
        )}
        {!isLoading && errorSignup && (
          <div className={classes.warning}>
            <br />
            An error occured, please try again!
          </div>
        )}
        {/* <div className={classes.control}>
          <label htmlFor="password">Repeat Password</label>
          <input
            type="password"
            id="passwordd"
            required
            ref={passwordRInputRef}
          />
        </div> */}
        <div className={classes.actions}>
          {!isLoading && (
            <button className={classes.authButton}>Create Account</button>
          )}
          {isLoading && <p>Registering..</p>}
          <Link to="/signin" className={classes.toggle}>
            Login with an existing account
          </Link>
        </div>
      </form>
    </section>
  );
};

export default SignupAuthForm;
