import React, { useState } from "react";

//All the svg files
import Home from "../../assets/home-solid.svg";
import LogoImage from "../../assets/uni_logo2.svg";
import About from "../../assets/about_us.svg";
import Add from "../../assets/add-image.svg";
import ImageList from "../../assets/image.svg";
import Contact from "../../assets/contact.svg";
import Signin from "../../assets/signin.svg";
import Signup from "../../assets/signup.svg";
import Signout from "../../assets/singout.svg";
import Admin from "../../assets/admin.png";

import { useContext } from "react";
import { getAuth, signOut } from "firebase/auth";
import AuthContext from "../../Store/authContext";
import classes from "./navBar.module.css";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

const Container = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  margin-top: 0px !important;
  margin: 0%;
  position: fixed;
  .active {
    border-right: 4px solid var(--white);
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
`;

const Button = styled.button`
  margin-top: 10%;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--black);
  border: none;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  margin: 0.5rem 0 0 0.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  &::before,
  &::after {
    content: "";
    background-color: var(--white);
    height: 2px;
    width: 1rem;
    position: absolute;
    transition: all 0.4s ease;
  }
  &::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    top: ${(props) => (props.clicked ? "1.5" : "1rem")};
    transform: ${(props) => (props.clicked ? "rotate(135deg)" : "rotate(0)")};
  }
  &::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    top: ${(props) => (props.clicked ? "1.2" : "1.5rem")};
    transform: ${(props) => (props.clicked ? "rotate(-135deg)" : "rotate(0)")};
  }
`;

const SidebarContainer = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--black);
  width: 3.5rem;
  height: 40vh;
  margin-top: 1rem;
  border-radius: 0 20px 20px 0;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

const Logo = styled.div`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: 3rem;
  img {
    width: 100%;
    height: auto;
  }
`;

const SlickBar = styled.ul`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  color: var(--white);
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--black);
  padding: 2rem 0;
  position: absolute;
  top: 5rem;
  left: 0;
  width: ${(props) => (props.clicked ? "12rem" : "3.5rem")};
  transition: all 0.4s ease;
  //Transparncy:
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=999)"; /* IE 8 */
  filter: alpha(opacity=999); /* IE 5-7 */
  -moz-opacity: 0.999; /* Netscape */
  -khtml-opacity: 0.999; /* Safari 1.x */
  opacity: 0.999; /* Good browsers */
  border-radius: 0 20px 20px 0;
`;

const Item = styled(NavLink)`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--white);
  width: 100%;
  // height: 3.5rem;
  padding: 1rem 0;
  cursor: pointer;
  display: flex;
  padding-left: 1rem;
  &:hover {
    border-right: 4px solid var(--white);
    img {
      filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg)
        brightness(103%) contrast(103%);
    }
  }
  img {
    width: 1.2rem;
    height: auto;
    filter: invert(92%) sepia(4%) saturate(1033%) hue-rotate(169deg)
      brightness(78%) contrast(85%);
  }
`;


const Text = styled.span`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  width: ${(props) => (props.clicked ? "100%" : "0")};
  height: 1.2rem;
  overflow: hidden;
  margin-left: ${(props) => (props.clicked ? "1.5rem" : "0")};
  transition: all 0.4s ease;
`;

const Logout = styled.button`
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
  cursor: pointer;
  img {
    width: 100%;
    height: auto;
    filter: invert(25%) sepia(40%) saturate(6073%) hue-rotate(2deg)
      brightness(100%) contrast(80%);
    transition: all 0.4s ease;
    &:hover {
      border: none;
      padding: 0;
      opacity: 0.5;
    }
  }
`;

const Barrier = styled.div`
  margin-top: 30px;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  color: var(--white);
  width: 100%;
  // height: 3.5rem;
  padding: 1rem 0;
  display: flex;
  padding-left: 1rem;
  border-top: 1px solid var(--grey);
  height: 1%;
  width: 54px;
`;

const Sidebar = () => {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);



  const authCtx = useContext(AuthContext);
  // authCtx.updateValues();
  const isLoggedIn = authCtx.isLoggedIn;
  const isAdmin = authCtx.admin;

  const signoutHandler = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log("Signed out!");
        authCtx.logout();
      })
      .catch((error) => {
        console.log("Error Logging out!!!");
      });
  };

  return (
    <div className={classes.reset}>
      <Container>
        <Button clicked={click} onClick={() => handleClick()}>
          Click
        </Button>
        <SidebarContainer>
          <Logo title="University of Haifa - אוניברסיטת חיפה">
            <img src={LogoImage} alt="logo" />
          </Logo>
          <SlickBar clicked={click}>
            <Tippy content="Home" placement="right">
              <Item
                onClick={() => setClick(false)}
                exact
                activeClassName="active"
                to="/"
              >
                <img src={Home} alt="Home" />
                <Text clicked={click}>Home</Text>
              </Item>
            </Tippy>
            <Tippy content="Image List" placement="right">
              <Item
                onClick={() => setClick(false)}
                activeClassName="active"
                to="/list"
              >
                <img src={ImageList} alt="List goes here" />
                <Text clicked={click}>Image List</Text>
              </Item>
            </Tippy>
            <Tippy content="Add Image" placement="right">
              <Item
                onClick={() => setClick(false)}
                activeClassName="active"
                to="/add_image"
              >
                <img src={Add} alt="Click here to Add" />
                <Text clicked={click}>Add Image</Text>
              </Item>
            </Tippy>
            <Tippy content="About us" placement="right">
              <Item
                onClick={() => setClick(false)}
                activeClassName="active"
                to="/about"
              >
                <img src={About} alt="About" />
                <Text clicked={click}>About us</Text>
              </Item>
            </Tippy>
            <Tippy content="Contact" placement="right">
              <Item
                onClick={() => setClick(false)}
                activeClassName="active"
                to="/contact"
              >
                <img src={Contact} alt="Contact" />
                <Text clicked={click}>Contact</Text>
              </Item>
            </Tippy>

            {isAdmin === "yes" && (
              <Tippy content="Admin" placement="right">
                <Item
                  onClick={() => setClick(false)}
                  activeClassName="active"
                  to="/admin"
                >
                  <img src={Admin} alt="Admin" />
                  <Text clicked={click}>Administration</Text>
                </Item>
              </Tippy>
            )}

            <Barrier></Barrier>

            {!isLoggedIn && (
              <Tippy content="Sign in" placement="right">
                <Item
                  onClick={() => setClick(false)}
                  activeClassName="active"
                  to="/signin"
                >
                  <img src={Signin} alt="Singin" />
                  <Text clicked={click}>Sign in</Text>
                </Item>
              </Tippy>
            )}
            {!isLoggedIn && (
              <Tippy content="Sign up" placement="right">
                <Item
                  onClick={() => setClick(false)}
                  activeClassName="active"
                  to="/sign-up"
                >
                  <img src={Signup} alt="Singup" />
                  <Text clicked={click}>Sign up</Text>
                </Item>
              </Tippy>
            )}
            {isLoggedIn && (
              <Tippy content="Sign out" placement="right">
                <Logout>
                  <img src={Signout} alt="logout" onClick={signoutHandler} />
                </Logout>
              </Tippy>
            )}
          </SlickBar>

          {/* <Profile clicked={profileClick}>
          <img
            onClick={() => handleProfileClick()}
            src="https://picsum.photos/200"
            alt="Profile"
          />
          <Details clicked={profileClick}>
            <Name>
              <h4>Jhon&nbsp;Doe</h4>
              <a href="/#">view&nbsp;profile</a>
            </Name>

            <Logout>
              <img src="/uni_logo_notext.png" alt="logout" />
            </Logout>
          </Details>
        </Profile> */}
        </SidebarContainer>
      </Container>
    </div>
  );
};

export default Sidebar;
