/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
  Line,
} from "recharts";

import axios from "axios";
import { v4 as uuid } from "uuid";
import { db } from "../Config/firebase";
import { collection, getDocs, limit, query, where } from "firebase/firestore/lite";
import Api from "../Config/api";

function Home() {

  const [optionsFromDB, setOptionsFromDB] = useState([]);
  const [selectedFeat, setSelectedFeat] = useState(null);
  const [imagesWithFeat, setImagesWithFeat] = useState(null);
  const [featCount, setFeatCount] = useState(null);
  const [isGeneratingLink, setIsGeneratingLink] = useState(false);
  const [selectedOpt, setSelectedOpt] = useState(null);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [pickedFeatures, setPickedFeatures] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [isGeneratingZip, setIsGeneratingZip] = useState(false);
  const [imagesWithOpts, setImagesWithOpts] = useState([]);

  useEffect(() => {
    async function setOpts() {
      const opts = await (
        await axios.get("https://fantasy-art-api-vrgjyif7jq-nw.a.run.app" + "/getOptions")
      ).data;
      setOptionsFromDB(opts);
      setSelectedFeat(opts[0]);
      setSelectedFeature(opts[0]);
    }
    setOpts()
  }, []);

  useEffect(() => {
   async function setDefFeat(){
    if (!selectedFeature) return;
    return setSelectedOpt(selectedFeature.vals[0]);
   }
   setDefFeat()
  }, [selectedFeature]);

  useEffect(() => {
    async function optsFromDB () {
      if (!selectedFeat) return;
        const myArr = []
      selectedFeat.vals.map(async (feat) => {

      // comment code below this when elasticsearch is sync with firebase
        const q = query(
          collection(db, "Images"),
          where(selectedFeat.name, "==", feat.value),
          limit(1000)
          );
          const querySnapshot = await getDocs(q)
          myArr.push(querySnapshot.docs.map(doc => doc.data()))

          const uniqueData = myArr.flat(10).filter(
            (thing, index, self) =>
            index === self.findIndex((t) => t.id === thing.id)
          );
          setImagesWithFeat(uniqueData);
          setFeatCount(null);
      // comment code above this when elasticsearch is sync with firebase
      
      // uncomment code below this when elasticsearch is sync with firebase
        // const {data} = await (await Api().post("search", JSON.stringify({
        //   "query": {
        //     "bool" : {
        //       "filter" : [ {"term": {[feat.feature]: feat.value}} ]
        //     }
        //   },
        //   "size": 10000
        // }))).data
        // myArr.push(data)
        // setImagesWithOpts(myArr.flat(10));
        // setIsSearching(false);
      // comment code above this when elasticsearch is sync with firebase
    })
    }
    optsFromDB()
  }, [optionsFromDB, selectedFeat]);

  useEffect(() => {
    async function handleSetWithFeat() {
      if (!imagesWithFeat) return;
      setFeatCount(selectedFeat.vals.map(val => {
        return {
          name: val.value,
          images: imagesWithFeat.filter(img => img[selectedFeat.name] === val.value),
          value: imagesWithFeat.filter(img => img[selectedFeat.name] === val.value).length
        }
      }))
    }
    handleSetWithFeat()
  }, [imagesWithFeat]);

  useEffect(() => {
    async function handleSetPickedFeatures () {
      if (!pickedFeatures) return;
    const options = pickedFeatures.reduce((acc, curr) => {
      return acc.concat(String(curr.value));
    }, []);

    const abortCont = new AbortController();
    // setTimeout(() => {
      if (options.length > 0) {
        setIsSearching(true);
        setImagesWithOpts([]);
      }
      const myArr = []

      pickedFeatures.map(async (feat) => {
        // comment code below this when elasticsearch is sync with firebase
        const q = query(
          collection(db, "Images"),
          where(feat.feature, "==", feat.value),
          limit(1000)
        );
        const querySnapshot = await getDocs(q)
        myArr.push(querySnapshot.docs.map(doc => doc.data()))
        setImagesWithOpts(myArr.flat(10));
        setIsSearching(false);
        
        // uncomment code below this when elasticsearch is sync with firebase
        // const {data} = await (await Api().post("search", JSON.stringify({
          //   "query": {
            //     "bool" : {
              //       "filter" : [ {"term": {[feat.feature]: feat.value}} ]
        //     }
        //   },
        //   "size": 10000
        // }))).data
        // myArr.push(data)
        // setImagesWithOpts(myArr.flat(10));
        // setIsSearching(false);
      })
    }
    handleSetPickedFeatures()
  }, [pickedFeatures]);

  const downloadZip = async () => {
    if (!imagesWithFeat) return;
    setIsGeneratingLink(true);
    const getZipOfImages = await (
      await axios.post("https://fantasy-art-api-vrgjyif7jq-nw.a.run.app" + "/generate/csv", {
        opts: selectedFeat.vals,
        imagesWithFeat,
      })
    ).data;
    window.open(getZipOfImages.url);
    setIsGeneratingLink(false);
  };

  const handleDownloadImagesByFeat = async (opt) => {
    if (!imagesWithFeat) return;
    setIsGeneratingLink(true);
    const getZipOfImages = await (
      await axios.post("https://fantasy-art-api-vrgjyif7jq-nw.a.run.app" + "/generate/csv", {
        opts: [{ value: opt.name }],
        imagesWithFeat: opt.images,
      })
    ).data;
    window.open(getZipOfImages.url);
    setIsGeneratingLink(false);
  };

  const handleDownloadImagesByOpt = async (opt) => {
    if (!imagesWithOpts) return;
    setIsGeneratingZip(true);
    const getZipOfImages = await (
      await axios.post("https://fantasy-art-api-vrgjyif7jq-nw.a.run.app" + "/generate/csv", {
        opts: pickedFeatures,
        imagesWithFeat: imagesWithOpts,
      })
    ).data;
    window.open(getZipOfImages.url);
    setIsGeneratingZip(false);
  };

  // a range function that takes a start and end number and returns an array of numbers as range in python
  const range = (start, end) => {
    const arr = [];
    for (let i = start; i <= end; i++) {
      arr.push(i);
    }
    return arr;
  };

  const dataRef = [
    { name: "Tagged", value: 11423 },
    { name: "Untagged", value: 53492 },
    { name: "In Progress", value: 3147 },
  ];
  const [data] = useState(dataRef);

  // function clickHandler(e) {
  //   const dataRef = [
  //     { name: "Group A", value: 1000 },
  //     { name: "Group B", value: 300 },
  //     { name: "Group C", value: 300 },
  //     { name: "Group D", value: 200 },
  //     { name: "Group E", value: 278 },
  //     { name: "Group F", value: 189 },
  //   ];
  //   setData(dataRef);
  //   console.log(e.name);
  // }


  // a constant named COLORS that contains an array of distinct 30 color names
  const COLORS = [
    "#e6194b",
    "#3cb44b",
    "#ffe119",
    "#4363d8",
    "#f58231",
    "#911eb4",
    "#46f0f0",
    "#f032e6",
    "#bcf60c",
    "#fabebe",
    "#008080",
    "#e6beff",
    "#9a6324",
    "#fffac8",
    "#800000",
    "#aaffc3",
    "#808000",
    "#ffd8b1",
    "#000075",
    "#808080",
    "#ffffff",
    "#000000",
    "#0088FE",
    "#00C49F",
    "#FFBB28",
    "#FF8042",
    "#FF0000",
    "#FF00FF",
    "#FF0080",
    "#FF0040",
    "#FF0000",
    "#FF00FF",
    "#FF0080",
  ];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  return (
    <div>
      <div style={{ marginLeft: 90 }}>
        <h1 style={{ textAlign: "center", paddingTop: 40 }}>
          Welcome to the Fantasy Art Master
        </h1>
        This website is used for the development of a dataset of fantasy art
        images semantically tagged with art-related tags and themes. In order to
        participate in the tagging process, please sign up to the website using
        the sign up button above.
      </div>
      <ResponsiveContainer width={"90%"} height={550}>
        <PieChart style={{ marginLeft: "10%" }} width={400} height={400}>
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data}
            cx="45%"
            cy="45%"
            outerRadius={210}
            fill="#8884d8"
            label
            // onClick={clickHandler}
            margin={{ top: 0, left: 0, right: 50, bottom: 0 }}
          >
            {data && data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign="top"
            align="right"
            wrapperStyle={{
              right: "46%",
              top: "95%",
              paddingBottom: 10,
              display: "block",
            }}
            iconType="circle"
            iconSize={10}
          />
          <Line
            name="pv of pages"
            type="monotone"
            dataKey="value"
            stroke="#8884d8"
          />
          <Line
            name="uv of pages"
            type="monotone"
            dataKey="value"
            stroke="#82ca9d"
          />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>

      <div className="w-full p-24 bg-gray-50">
        <div className="px-6 py-4 mb-16 bg-white rounded-lg shadow-md">
          {selectedFeat ? (
            <div className="flex border border-gray-100 items-center  gap-4 px-4 py-2 rounded-lg bg-white">
              <p>Choose Feature:</p>
              {selectedFeat && (
                <select
                  value={selectedFeat.name}
                  onChange={(e) =>
                    setSelectedFeat(
                      optionsFromDB[
                        optionsFromDB.findIndex(
                          (opt) => opt.name === e.target.value
                        )
                      ]
                    )
                  }
                  className="bg-white px-4 py-2 rounded border"
                >
                  {optionsFromDB &&
                    optionsFromDB.map((opt) => (
                      <option
                        key={uuid()}
                        className="calitalize"
                        value={opt.name}
                      >
                        {opt.name}
                      </option>
                    ))}
                </select>
              )}
            </div>
          ) : (
            <div className="flex border border-gray-100 items-center  gap-4 px-4 py-6 rounded-lg bg-white">
              <div className="w-24 h-8 bg-gray-200 rounded-full animate-pulse"></div>
              <div className="w-32 h-8 bg-gray-200 rounded-full animate-pulse"></div>
            </div>
          )}
        </div>



        {featCount && (
          <div className="flex items-end">
            <ResponsiveContainer width={"90%"} height={700}>
              <PieChart width={"90%"} height={600}>
                <Legend
                  layout="horizontal"
                  verticalAlign="top"
                  align="center"
                  alphabetic={true}
                  accumulate="sum"
                />
                <Pie
                  data={featCount || []}
                  cx="45%"
                  cy="45%"
                  outerRadius={210}
                  labelLine={false}
                  isAnimationActive={true}
                  label={renderCustomizedLabel}
                  accumulate="sum"
                  fill="#8884d8"
                  dataKey="value"
                  margin={{ top: 0, left: 0, right: 50, bottom: 0 }}
                >
                  {featCount.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  )) || []}
                </Pie>
                <Tooltip />
              </PieChart>
            </ResponsiveContainer>
            <div className="md:absolute right-0 px-6 py-6 mb-64 mr-auto w-4/12">
              <button
                disabled={isGeneratingLink}
                onClick={() => downloadZip()}
                className={`px-6 py-2 rounded-full ${
                  isGeneratingLink
                    ? "bg-gray-200 text-gray-800"
                    : "bg-blue-500 text-white"
                }`}
              >
                {isGeneratingLink
                  ? "Generating Link..."
                  : "Download all the images"}{" "}
              </button>
            </div>
          </div>
        )}
        <div className="max-h-96 overflow-scroll">
        <table className="min-w-full divide-y divide-gray-200 border border-gray-300 table-fixed dark:divide-gray-700">
          <thead className="bg-gray-100 dark:bg-gray-700 ">
            <tr>
              <th
                scope="col"
                className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
              >
                Option
              </th>
              <th
                scope="col"
                className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
              >
                Number of images
              </th>
              <th
                scope="col"
                className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
              >
                Download Images
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
            {featCount
              ? featCount.map((opt) => (
                  <tr key={uuid()}>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 dark:text-gray-100">
                      {opt.name}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 dark:text-gray-100">
                      {opt.value}
                    </td>
                    <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 dark:text-gray-100">
                      <button
                        onClick={() => handleDownloadImagesByFeat(opt)}
                        className="text-blue-500 hover:text-blue-700"
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                ))
              : range(0, 5).map((skele) => (
                  <tr key={uuid()}>
                    {range(0, 3).map((col) => (
                      <td
                        key={uuid()}
                        className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 dark:text-gray-100"
                      >
                        <div className=" rounded-full animate-pulse w-full bg-gray-200 py-3 px-6"></div>
                      </td>
                    ))}
                  </tr>
                ))}
          </tbody>
        </table>
        </div>

        {optionsFromDB && optionsFromDB.length > 0 && selectedFeature && (
          <div className="w-full divide-y py-6 bg-white px-8 shadow-md mt-16">
            <div className="flex gap-4 items-center">
              <div className="flex gap-2 items-center">
                <p>Choose Feature:</p>
                <select
                  value={selectedFeature.name}
                  onChange={(e) =>
                    setSelectedFeature(
                      optionsFromDB[
                        optionsFromDB.findIndex(
                          (opt) => opt.name === e.target.value
                        )
                      ]
                    )
                  }
                  className="bg-white px-4 py-2 rounded border"
                >
                  {optionsFromDB &&
                    optionsFromDB.map((opt) => (
                      <option
                        key={uuid()}
                        className="calitalize"
                        value={opt.name}
                      >
                        {opt.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="flex gap-2 items-center">
                <p>Choose Option:</p>
                {selectedFeature && selectedFeature.vals && selectedOpt && (
                  <select
                    value={selectedOpt.label}
                    onChange={(e) =>
                      setSelectedOpt(
                        selectedFeature.vals[
                          selectedFeature.vals.findIndex(
                            (opt) => opt.value === e.target.value
                          )
                        ]
                      )
                    }
                    className="bg-white px-4 py-2 rounded border"
                  >
                    {selectedFeature ? (
                      selectedFeature.vals.map((opt) => (
                        <option
                          key={uuid()}
                          className="calitalize"
                          value={opt.value}
                        >
                          {opt.label}
                        </option>
                      ))
                    ) : (
                      <option key={uuid()} className="calitalize" value="">
                        loading..
                      </option>
                    )}
                  </select>
                )}
              </div>
              <button
                onClick={() =>
                  setPickedFeatures((prevState) => {
                    if (
                      prevState.find(
                        (opt) =>
                          opt.feature === selectedFeature.name &&
                          opt.value === selectedOpt.value
                      )
                    )
                      return prevState;
                    const newPickedFeatures = [...prevState];
                    newPickedFeatures.push({
                      feature: selectedFeature.name,
                      value: selectedOpt.label,
                    });
                    return newPickedFeatures;
                  })
                }
                className="h-10 w-10 rounded-full bg-green-500 shadow text-white"
              >
                +
              </button>
            </div>
            <div className="w-full px-8 py-6 bg-white shadow-md mt-6">
              {pickedFeatures && pickedFeatures.length > 0 ? (
                <div className="flex gap-4 flex-wrap">
                  {pickedFeatures && pickedFeatures.map((feature) => (
                    <div
                      key={uuid()}
                      className="flex items-center justify-between bg-gray-100 px-4 py-2 rounded-lg bg-white shadow-md"
                    >
                      <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                        {feature.feature} - {feature.value}
                      </p>
                      <button
                        onClick={() =>
                          setPickedFeatures((prevState) => {
                            const newPickedFeatures = [...prevState];
                            newPickedFeatures.splice(
                              newPickedFeatures.findIndex(
                                (opt) =>
                                  opt.feature === feature.feature &&
                                  opt.value === feature.value
                              ),
                              1
                            );
                            return newPickedFeatures;
                          })
                        }
                        className="px-3  items-center justify-center flex rounded-full"
                      >
                        x
                      </button>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                  No Features Selected
                </p>
              )}
            </div>
            <div
              className={`overflow-y-scroll w-full ${
                imagesWithOpts !== [] ? "max-h-72" : "h-auto"
              }`}
            >
              <table className="min-w-full divide-y divide-gray-200 border border-gray-300 table-auto dark:divide-gray-700">
                <thead className="bg-gray-100 dark:bg-gray-700 ">
                  <tr>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Title
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Artist
                    </th>
                    <th
                      scope="col"
                      className="py-3 px-6 text-xs font-medium tracking-wider text-left text-gray-700 uppercase dark:text-gray-400"
                    >
                      Source-URL
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                  {imagesWithOpts && imagesWithOpts.length > 0
                    ? imagesWithOpts.map((img) => (
                        <tr key={uuid()}>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 dark:text-gray-100">
                            {img.title}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 dark:text-gray-100">
                            {img.artist}
                          </td>
                          <td className="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900 dark:text-gray-100">
                            <a
                              href={img.file_name.split("/")[0]}
                              // href={img.source_url}
                              target="blank"
                              className="underline text-blue-500 hover:text-blue-700"
                            >
                              GoTo
                            </a>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
              {!imagesWithOpts ||
                (imagesWithOpts.length === 0 && (
                  <div className="flex border items-center py-8 px-8 justify-center">
                    <p className="text-sm font-medium text-gray-900 dark:text-gray-100">
                      {isSearching
                        ? "Loading images..."
                        : "No Images Found or you haven't selected any option"}
                    </p>
                  </div>
                ))}
            </div>
            <div className="w-full">
              {imagesWithOpts && imagesWithOpts.length > 0 ? (
                <button
                  disabled={isGeneratingZip}
                  onClick={() => handleDownloadImagesByOpt()}
                  className={`mt-8 ml-auto px-6 py-2 rounded-full ${
                    isGeneratingZip
                      ? "bg-gray-200 text-gray-800"
                      : "bg-blue-500 text-white"
                  }`}
                >
                  {isGeneratingZip
                    ? "Generating Link..."
                    : "Download all the images"}{" "}
                </button>
              ) : (
                <button
                  disabled={true}
                  className="mt-8 ml-auto px-6 py-2 rounded-full bg-gray-200 text-gray-500"
                >
                  {isGeneratingZip
                    ? "Generating Link..."
                    : "Download all the images"}{" "}
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <div style={{ textAlign: "center", paddingTop: 40 }}>
        This page is in development
      </div>
    </div>
  );
}

export default Home;
