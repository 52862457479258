import AdminUserCard from "./adminUserCard";
import { app } from "../Config/firebase";
import React, { useState, useEffect } from "react";
import { collection, query, onSnapshot } from "firebase/firestore"; //Using this access method: https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
import firebase from "firebase/compat/app";
import classes from "./imageTable.module.css";
import { Helmet } from "react-helmet";
import { BounceLoader } from "react-spinners";

const AdminUsersList = () => {
  const [loading, setLoading] = useState(true);
  const [users, setUsers] = useState([]);
  const [, setError] = useState(false);

  useEffect(() => {
    // if (doc.exists) {
    let orgList = [];

    const q = query(collection(app, "administration")); //You can add a where condition here by adding an argument to query() such as where("state", "==", "CA")
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        orgList = snapshot.docs.map((doc) => ({
          email: doc.id,
          admin: doc.data().admin,
          addImage: doc.data().addImage,
          imageList: doc.data().imageList,
        }));
        setUsers(orgList);
        setLoading(false);
      },
      (res) => {
        setError(true);
        var user = firebase.auth().currentUser;
        console.log(res + " " + user);
      }
    );
    return () => unsubscribe();
  }, [users]);

  if (loading) {
    return (
      <div>
        <Helmet>
          <style>{"body { background-color: rgb(210, 208, 207); }"}</style>
        </Helmet>
        <div className={classes.loadingDiv}>
          <BounceLoader size="100" color="orange" />
        </div>
        <div className={classes.loadingDivText}>
          <h1>Loading...</h1>
        </div>
      </div>
    );
  }
  return (
    <div className="container mx-auto">
      <Helmet>
        <style>{"body { background-color: rgb(210, 208, 207); }"}</style>
      </Helmet>
      {users.length > 0 ? (
        users.map((user) => <AdminUserCard key={user.email} image={user} />)
      ) : (
        <h1>no answers yet :</h1>
      )}
    </div>
  );
};

export default AdminUsersList;
