/* eslint-disable react-hooks/exhaustive-deps */
import ImageCard from "./ImageCard";
import { db } from "../Config/firebase";
import React, { useState, useEffect } from "react";
// import { collection, query, where, onSnapshot } from "firebase/firestore"; //Using this access method: https://firebase.google.com/docs/firestore/query-data/listen#listen_to_multiple_documents_in_a_collection
import { useLocation } from "react-router-dom";
import classes from "./imageTable.module.css";
import { Helmet } from "react-helmet";
import { BounceLoader } from "react-spinners";
import { collection, getDoc, getDocs, orderBy, query, startAfter, where } from "firebase/firestore/lite";
import { limit } from "firebase/firestore";

const ImageCards = (props) => {
  const [loading, setLoading] = useState(true);
  const [images, setImages] = useState([]);
  const [, setError] = useState(false);

  
  const location = useLocation();
  
  const path_ = location.pathname;
  var path = path_.substring(1);

  useEffect(() => {
    // if (doc.exists) {
    let orgList = [];
    async function getImageDoc () {
      const q = query(
        collection(db, "Images"),
        where("file_name", "==", path),
      );
      const querySnapshot = await getDocs(q)
      orgList = querySnapshot.docs.map((doc) => ({
              id: doc.id,
              file_name: doc.data().file_name,
              title: doc.data().title,
              download_url: doc.data().download_url,
              // created_at: moment(doc.data().created_at)
              //   .format("MM-DD-YYYY")
              //   .toString(),
              created_at: doc.data().created_at.toDate().toString(),
              Artist: doc.data().artist,
              description: doc.data().description,
              source: doc.data().source,
              material: doc.data().material,
              pointInTime: doc.data().pointInTime,
              cosmology: doc.data().cosmology,
              kind: doc.data().kind,
              environment: doc.data().environment,
              narativity: doc.data().narativity,
              style: doc.data().style,
              motifs: doc.data().motifs,
              interactionWithViewer: doc.data().interactionWithViewer,
              assosiation: doc.data().assosiation,
              context: doc.data().context,
              figureKind: doc.data().figureKind,
              gender: doc.data().gender,
              race: doc.data().race,
              animals: doc.data().animals,
              vegetation: doc.data().vegetation,
              background: doc.data().background,
              attributes: doc.data().attributes,
              pose: doc.data().pose,
              orientation: doc.data().orientation,
              role: doc.data().role,
              figure_landscape: doc.data().figure_landscape,
              attribute_landscape: doc.data().attribute_landscape,
              figure_figure: doc.data().figure_figure,
              figure_attribute: doc.data().figure_attribute,
              taggingStatus: doc.data().taggingStatus,
            }))
            setImages(orgList);
            setLoading(false);

      // console.log(querySnapshot.docs[0].data())
    }
    // const q = query(
    //   collection(db, "Images"),
    //   where("file_name", "==", path)
    // ); //You can add a where condition here by adding an argument to query() such as where("state", "===", "CA")
    // const unsubscribe = onSnapshot(
    //   q,
    //   (snapshot) => {
    //     orgList = snapshot.docs.map((doc) => ({
    //       id: doc.id,
    //       file_name: doc.data().file_name,
    //       title: doc.data().title,
    //       download_url: doc.data().download_url,
    //       // created_at: moment(doc.data().created_at)
    //       //   .format("MM-DD-YYYY")
    //       //   .toString(),
    //       created_at: doc.data().created_at.toDate().toString(),
    //       Artist: doc.data().artist,
    //       description: doc.data().description,
    //       source: doc.data().source,
    //       material: doc.data().material,
    //       pointInTime: doc.data().pointInTime,
    //       cosmology: doc.data().cosmology,
    //       kind: doc.data().kind,
    //       environment: doc.data().environment,
    //       narativity: doc.data().narativity,
    //       style: doc.data().style,
    //       motifs: doc.data().motifs,
    //       interactionWithViewer: doc.data().interactionWithViewer,
    //       assosiation: doc.data().assosiation,
    //       context: doc.data().context,
    //       figureKind: doc.data().figureKind,
    //       gender: doc.data().gender,
    //       race: doc.data().race,
    //       animals: doc.data().animals,
    //       vegetation: doc.data().vegetation,
    //       background: doc.data().background,
    //       attributes: doc.data().attributes,
    //       pose: doc.data().pose,
    //       orientation: doc.data().orientation,
    //       role: doc.data().role,
    //       figure_landscape: doc.data().figure_landscape,
    //       attribute_landscape: doc.data().attribute_landscape,
    //       figure_figure: doc.data().figure_figure,
    //       figure_attribute: doc.data().figure_attribute,
    //       taggingStatus: doc.data().taggingStatus,
    //     }))
    //     setImages(orgList);
    //     // console.log(orgList);
    //     setLoading(false);
    //     // console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!')
    //   },
    //   () => {
    //     setError(true);
    //   }
    // );
    // return () => {
    //   console.log("Hey Cleanup!")
    //   return unsubscribe()
    // };
    getImageDoc()
  }, []);

  if (loading) {
    return (
      <div>
        <Helmet>
          <style>{"body { background-color: rgb(210, 208, 207); }"}</style>
        </Helmet>
        <div className={classes.loadingDiv}>
          <BounceLoader size="100" color="orange" />
        </div>
        <div className={classes.loadingDivText}>
          <h1>Loading...</h1>
        </div>
      </div>
    );
  }
  if (images.length > 0) {
    <h1>no answers yet :</h1>;
  }
  return (
    <div className="container mx-auto">
      {images.map((image) =>
        image.file_name === path ? (
          <ImageCard key={image.id} image={image} />
          // <h1 key={Math.random() * 3}>Hello</h1>
        ) : (
          " "
        )
      )}
    </div>
  );
};

// return (
//   <div className="container mx-auto">
//     {path}
//     {images.length > 0 ? (
//       images.map((image) =>  {path===image.id && (
//         <ImageCard  key={image.id}  image={image} />
//       )})
//     ) : (
//       <h1>no answers yet :</h1>
//     )}
//   </div>)

export default ImageCards;
