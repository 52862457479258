import React from 'react';
import ReactDOM from 'react-dom';
import './App.css';
import App from './App';
import { AuthContextProvider } from "./Store/authContext";

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <AuthContextProvider>
    {/* <React.StrictMode> */}
      <App />
    {/* </React.StrictMode> */}
  </AuthContextProvider>,
);
