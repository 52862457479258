import React from "react";
import { useState } from "react";
import classes from "./ImageCard.module.css";
import { db } from "../Config/firebase";
import "./react-tabs.css";

const AdminUserCard = ({ image }) => {
  let adminStr = "not an Admin.";
  if (image.admin === "yes") adminStr = "an Admin";

  const [, setAddImageValue] = useState("");
  const [, setImageListValue] = useState("");
  function changeHandler(event) {
    event.preventDefault();
    if (event.target.id === "addImage") {
      db.collection("administration")
        .doc(image.email)
        .update({ addImage: event.target.value })
        .then(() => {});
      setAddImageValue(event.target.value);
    } else if (event.target.id === "imageList") {
      db.collection("administration")
        .doc(image.email)
        .update({ imageList: event.target.value })
        .then(() => {});
      setImageListValue(event.target.value);
    }
  }

  return (
    <div className={classes.parentAdminPage}>
      {/* <Helmet>
        <style>{"body { background-color: #e9e3e3c7; }"}</style>
      </Helmet> */}
      <div  style={{marginLeft: "10%"}}>
        <p>
          Email: <b> {image.email}</b> is {adminStr}
        </p>
        Add Image Page Access level:{" "}
        <select
          name="addImage"
          id="addImage"
          value={image.addImage}
          onChange={changeHandler}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>{" "}
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;||&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        Image List Page Access Level:{" "}
        <select
          name="imageList"
          id="imageList"
          value={image.imageList}
          onChange={changeHandler}
        >
          <option value="0">0</option>
          <option value="1">1</option>
          <option value="2">2</option>
        </select>
      </div>
    </div>
    // <div> Email: {image.email} isAdmin:  {image.admin} Access Levels to Add Image page:  {image.addImage} Access Levels to Add Image List: {image.imageList}</div>
  );
};

export default AdminUserCard;
