import React from "react";
import classes from "../components/imageTable.module.css";

const About = () => {
  return (
    <div className={classes.divWrapAbout}>
      <h6 className={classes.titleAboutStatus}>
        This page is under development, it will be updated in the coming weeks!
      </h6>
      <h1 className={classes.titleAbout}>About the project</h1>

      <div className={classes.contentAboutH}>
        הפרויקט עוסק בחקר הז'אנר האומנותי "פנטסטיק ארט" (Fantastic Art) – ז'אנר
        בעל נוכחות רבה ברשת האינטרנט ובתעשיות השונות, אך מודר מחקרית מהדיסיפלינה
        של תולדות האמנות ומהתעשיה האמנותית (מוזיאונים,גלריות וכו'). בשלב הראשון
        – הפרויקט שם למטרה ליצור קורפוס גדול של דימויים דרך איסופם מרשת
        האינטרנט. בשלב הבא אנו שואפים לבצע זיהוי אוטומטי של אונטולוגייה ויזואלית
        הכולל קשרים עם אמנות עבר. כל זאת בעזרת כלים של לימוד מכונה. בשלבים
        הראשונים של הפרוייקט, התחלנו באיסוף הנתונים תוך לימוד סוגי האתרים השונים
        הקיימים ברשת, ויצרנו אונטולוגיה אומנותית משולבת בשפה הסמיוטית של תרבות
        הפנטסיה, היכולה לבחון את האיקונוגרפיה של היצירות. הפרויקט מזמין לחשוב על
        היצירה הבודדת כמו גם על הז'אנר השלם באופן חדשני, דמוקרטי ובמתודה הבוחנת
        אומנות מלמטה למעלה.
      </div>

      <div className={classes.contentAboutE}>
        This project is aimed to study the “Fantastic Art” genre, which is an
        extremely salient artistic genre on the internet web, as well as other
        industries. This visual genre is neither studied in the art history
        discipline nor is it represented in GLAM (Galleries, Libraries and
        Museums) institutions. In the first phase, the project’s aim is to
        collect a large corpus of images from the internet web. In the next
        phase we aspire to develop machine learning of a visual ontology and
        juxtapose it with the art of the past. In the first phases of the
        project, we started the data collection while learning the different
        kinds of websites and created an artistic ontology combined with the
        semiotic language of the Fantastic Art culture. This ontology can
        support an iconographic analysis of the artworks. The project will
        invite a new method of thinking of the single artwork as well as on an
        entire genre, in an innovating and democratic bottom-up manner.
      </div>
    </div>
  );
};

export default About;
